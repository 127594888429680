import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { getAllCompanies } from "./actions/companyActions";
// import { getAllIssues } from "./actions/issues";
import { getAllUsers } from "./actions/userActions";
import { checkStockPrices } from "./actions/stockActions";
import LandingPage from "./components/LandingPage";
import AddCompany from "./components/Admin/AddCompany";
import AddIssue from "./components/Admin/AddIssue";
import CompanyProfile from "./components/Index/CompanyProfile";
import EditIssue from "./components/Admin/EditIssue";
import Engage from "./components/Engage";
// import EngageProfilePage from "./components/Engage/EngageProfilePage";
import FooterNav from "./components/FooterNav";
import HeaderNav from "./components/HeaderNav";
import Index from "./components/Index/Index";
import IndexMembers from "./components/Index/IndexMembers";
import LogIn from "./components/LogIn";
import Nick from "./components/Nick";
import PivotalIssues from "./components/PivotalIssues";
import Register from "./components/Register";
import SignUp from "./components/Users/SignUp";
import SustainTen from "./components/Index/SustainTen";
import SustainTenFeed from "./components/Index/SustainTenFeed";
import SymbolChecker from "./pages/SymbolChecker";
import User from "./components/Users/User";
import ViewIssue from "./pages/ViewIssue";
import withAuthentication from "./components/Session/Authentication";

// import DEV from "./components/DEV";
import "./App.css";
var mixpanel = require("mixpanel-browser");

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: "not-active dropdown",
      disableClickOut: true
    };
    this.collapse = this.collapse.bind(this);
    this.toggleVisibility = this.toggleVisibility.bind(this);
  }
  componentDidMount() {
    mixpanel.init("6f4eff88d17f998a5dcfcecfb21131e9", {
      opt_out_tracking_by_default: false
    });
    // getAllIssues();
    getAllCompanies();
    checkStockPrices();
    getAllUsers();
    // mixpanel.init("6f4eff88d17f998a5dcfcecfb21131e9", {
    //   opt_out_tracking_by_default: false
    // });
  }
  toggleVisibility = () => {
    if (this.state.visible === "not-active dropdown") {
      this.setState({
        visible: "is-active dropdown",
        disableClickOut: false
      });
    } else if (this.state.visible === "is-active dropdown") {
      this.setState({
        visible: "not-active dropdown",
        disableClickOut: true
      });
    }
  };

  collapse() {
    this.setState({
      visible: "not-active dropdown",
      disableClickOut: true
    });
  }

  render() {
    return (
      <Router>
        <div id="container-div">
          <HeaderNav
            style={{ zIndex: "100" }}
            visible={this.state.visible}
            collapse={this.collapse}
            toggle={this.toggleVisibility}
            disableOnClickOutside={this.state.disableClickOut}
          />
          <div id="app-div">
            <br />
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/addIssue" component={AddIssue} />
            <Route exact path="/addCompany" component={AddCompany} />
            <Route exact path="/login" component={LogIn} />
            <Route exact path="/index" component={Index} />
            <Route exact path="/sustain-ten" component={SustainTen} />
            <Route exact path="/sustain-ten-feed" component={SustainTenFeed} />
            <Route exact path="/index-members" component={IndexMembers} />
            <Route exact path="/issue/:issueId" component={ViewIssue} />
            <Route
              exact
              path="/company/:companyId"
              component={CompanyProfile}
            />
            <Route exact path="/register" component={Register} />
            <Route exact path="/edit-issue/:issueId" component={EditIssue} />
            <Route exact path="/engage" component={Engage} />
            {/* <Route exact path="/engage/:userId" component={EngageProfilePage} /> */}
            <Route exact path="/pivotal-issues" component={PivotalIssues} />
            <Route exact path="/nick" component={Nick} />
            <Route exact path="/users/:userId" component={User} />
            <Route exact path="/sign-up" component={SignUp} />
            <Route exact path="/symbol" component={SymbolChecker} />
            {/*} <Route exact path="/DEV" component={DEV} />*/}
            <br />
            <FooterNav />
          </div>
        </div>
      </Router>
    );
  }
}

export default withAuthentication(App);
