import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import { plusOne, minusOne } from "../actions/issues";
import TimeAgo from "react-timeago";
import { getAllIssues } from "../actions/issues";
import { getAllCompanies } from "../actions/companyActions";
import { checkStockPrices } from "../actions/stockActions";

class ViewIssue extends Component {
  componentDidMount() {
    checkStockPrices()
    getAllIssues();
    getAllCompanies();
  }
  render() {
    let result;
    let theCompany;
    let theIssue = {};
    let finalCompany = {};
    let stockTicker = {};
    if (Object.values(this.props.allIssues).length > 1) {
      // console.log("Just the one issue " + this.props.match.params.issueId);
      let allIssuesArray = Object.values(this.props.allIssues);

      result = allIssuesArray.filter(
        issue => issue["issueUid"] === this.props.match.params.issueId
      );
      theIssue = result[0];
      // console.log("result " + result[0].title);
      if (Object.values(this.props.allCompanies).length > 1) {
        theCompany = this.props.allCompanies.filter(
          company => company["companyUid"] === theIssue.companyKey
        );
        finalCompany = theCompany[0];
        stockTicker = finalCompany.stock;
        // console.log("the company " + finalCompany.name);
      }
    }
    let stockPrice;

    if (
      finalCompany !== undefined &&
      this.props.allStocks[`${stockTicker}`] !== undefined
    ) {
      stockPrice = this.props.allStocks[`${stockTicker}`].price;
    }

    return (
      <div className="card" style={styles.profileComponentStyle}>
        <div className="card-content" style={styles.cardContentStyle}>
          <header className="card-header">{theIssue.title} </header>

          <div />
          <div className="card-content" style={styles.segmentStyle}>
            {/* <Item.Group divided> */}
            {this.props.allIssues ? (
              <div className="card-content">
                <header className="card-header">
                  <Link to={`/company/${finalCompany.companyUid}`}>
                    {finalCompany.name}
                  </Link>
                </header>
                <div className="field">Stock Price {stockPrice}</div>
                {/* <button style={{ color: "red" }} onClick={this.handleMinus}>
                    -
                  </button> */}
                {/* <Label style={{ color: "red" }}>{theIssue.minusCount}</Label> */}
                {/* <button style={{ color: "green" }} onClick={this.handleAdd}>
                    +
                  </button> */}
                {/* <Label style={{ color: "green" }}>{theIssue.plusCount}</Label> */}
                <div className="field">
                  <TimeAgo
                    // date={}
                    style={{ textAlign: "right" }}
                  />
                  <div
                    style={{
                      justifyContent: "space-around",
                      // backgroundColor: "blue",
                      height: 15
                    }}
                  />
                </div>
                <h4>Summary:</h4>
                <p>{theIssue.summary}</p>
              </div>
            ) : (
              <div>{""}</div>
            )}
            {/* </Item.Group> */}
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  profileComponentStyle: {
    padding: 0,
    marginBottom: 10,
    borderWidth: 10,
    boxShadow: "none",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
    // backgroundImage: `url(${Background})`
  },
  unorderedListStyle: {
    listStyle: "none"
  },
  cardHeaderStyle: {
    // backgroundColor: "#003533",
    backgroundColor: "#404040",
    color: "white",
    padding: 3,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingLeft: 25,
    margin: 0
  },
  cardContentStyle: {
    padding: 0,
    margin: 0
  },
  segmentStyle: {
    // backgroundImage: `url(${Background})`,
    borderWidth: 0
  },
  divStyle: {
    height: 7,
    backgroundImage: "linear-gradient(to right, #FFDF00, #F63800, #6400B5)"
  }
};
const mapStateToProps = state => ({
  allIssues: state.issues.currentIssuesProps,
  allCompanies: state.companies.currentCompaniesProps,
  allStocks: state.stocks.currentStockProps
});

export default connect(mapStateToProps)(ViewIssue);
