import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { loginUser, forgotPassword } from "../actions/authActions";
var mixpanel = require("mixpanel-browser");

class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
  }
  handleSubmit(e) {
    e.preventDefault();
    loginUser(this.state.email, this.state.password);

    mixpanel.track("Login", {
      Email: this.state.email
    });
  }
  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleForgotPassword(e) {
    e.preventDefault();
    if (!this.state.email) {
      window.alert("Please enter your email and then click forgot");
    }
    forgotPassword(this.state.email);
  }
  render() {
    if (this.props.authUser !== null) {
      return <Redirect to="/" />;
    }
    // if (this.state.redirectToNewPage) {
    //   return <Redirect to="/" />;
    // }
    return (
      <Fragment>
        <div className="card" style={styles.profileComponentStyle}>
          <div className="card-content" style={styles.cardContentStyle}>
            <div style={styles.divStyle} />
            <div className="card-content" style={styles.segmentStyle}>
              <form
                id="theUploadForm"
                onSubmit={this.handleSubmit}
                style={styles.formStyle}
              >
                <div className="field" style={styles.fieldStyle}>
                  <label className="label">Email </label>
                  <input
                    className="input"
                    placeholder="email"
                    name="email"
                    autoComplete="email"
                    onChange={this.handleInputChange}
                  />
                </div>

                <div className="field" style={styles.fieldStyle}>
                  <label className="label">Password</label>

                  <input
                    className="input"
                    placeholder="password"
                    type="password"
                    onChange={this.handleInputChange}
                    name="password"
                    autoComplete="current-password"
                  />
                </div>

                <div className="field" style={styles.fieldStyle}>
                  <button
                    className="button is-info is-outlined"
                    style={styles.buttonStyle}
                    onClick={this.handleSubmit}
                  >
                    Log In
                  </button>

                  <button
                    className="button is-outlined"
                    onClick={this.handleForgotPassword}
                    style={styles.buttonStyle}
                  >
                    {" "}
                    Forgot Password
                  </button>
                </div>
              </form>
            </div>
            <div style={styles.registerStyle}>
              <Link
                className="button is-success is-outlined"
                to="/register"
                style={styles.registerButtonStyle}
              >
                {" "}
                Sign Up !
              </Link>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const styles = {
  profileComponentStyle: {
    marginBottom: 10,
    borderWidth: 0,
    boxShadow: "none",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
    // backgroundImage: `url(${Background})`
  },

  cardContentStyle: {
    padding: 0,
    margin: 0
  },
  fieldStyle: {
    width: "80%",
    margin: "0 auto"
  },
  registerButtonStyle: {
    padding: "1.25em 2em",
    margin: "auto",
    alignSelf: "center"
  },
  registerStyle: {
    width: "100%",
    height: "100%",
    display: "flex",
    marginTop: "100px",
    marginBottom: "50px",
    justifyContent: "center"
  },
  segmentStyle: {
    boxShadow: "none",
    borderWidth: 0
    // backgroundImage: `url(${Background})`
  },
  divStyle: {
    height: 7,
    backgroundImage: "#FFDF00"
  },
  buttonStyle: {
    margin: "10px 10px 10px 0"
  }
};

const mapStateToProps = state => ({
  allIssues: state.issues.currentIssuesProps,
  allCompanies: state.companies.currentCompaniesProps,
  authUser: state.session.authUser,
  currentUserProps: state.user.currentUserProps
});

export default connect(mapStateToProps)(LogIn);
