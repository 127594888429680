import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import {
//   FacebookShareButton,
//   TwitterShareButton,
//   TwitterIcon
// } from "react-share";
import Confetti from "react-dom-confetti";
import { plusOne, minusOne } from "../actions/issues";
import { vote } from "../actions/userActions";
import VoteResults from "./Issues/VoteResults";
import Comments from "./Users/Comments";
var FontAwesome = require("react-fontawesome");
var mixpanel = require("mixpanel-browser");
var Parser = require("html-react-parser");

const config = {
  angle: 90,
  spread: 60,
  startVelocity: 54,
  elementCount: 200,
  decay: 0.88
};

class IssueCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plusCount: this.props.issue.plusCount || 0,
      minusCount: this.props.issue.minusCount || 0,
      sharing: "hidden",
      summaryClass: "hidden",
      readMore: "",
      seeLess: "hidden",
      userVote: null,
      voted: false,
      company: ""
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleMinus = this.handleMinus.bind(this);
    this.hideShare = this.hideShare.bind(this);
    this.showShare = this.showShare.bind(this);
    this.toggleSummaryClassHidden = this.toggleSummaryClassHidden.bind(this);
    this.toggleSummaryClassVisible = this.toggleSummaryClassVisible.bind(this);
    this.renderVote = this.renderVote.bind(this);
  }

  componentDidMount() {}

  toggleSummaryClassHidden() {
    this.setState({ summaryClass: "hidden", readMore: "", seeLess: "hidden" });
  }
  toggleSummaryClassVisible() {
    this.setState({ summaryClass: "", readMore: "hidden", seeLess: "" });
  }
  handleAdd() {
    if (this.state.voted === true) {
      return;
    } else {
      let newCount = this.state.plusCount + 1;
      this.setState({
        plusCount: newCount,
        voted: true,
        userVote: true
      });
      mixpanel.track("VOTE", {
        direction: "SUSTAIN",
        company: this.state.company,
        isssue_title: this.props.issue.title,
        issue_uid: this.props.issue.issueUid,
        time_added: Date.now(),
        user_email: this.props.currentUserProps.email,
        user_uid: this.props.currentUserProps.uid,
        user_username: this.props.currentUserProps.username
      });
      plusOne(this.props.issue.issueUid);
      this.props.increase(this.props.issue.issueUid);
      if (this.props.currentUserProps.uid) {
        vote(this.props.currentUserProps.uid, this.props.issue.issueUid, true);
      }
    }
  }

  handleMinus() {
    if (this.state.voted === true) {
      return;
    } else {
      let newMinus = this.state.minusCount - 1;
      this.setState({
        minusCount: newMinus,
        voted: true,
        userVote: false
      });
      mixpanel.track("VOTE", {
        direction: "UNSUSTAIN",
        company: this.state.company,
        isssue_title: this.props.issue.title,
        issue_uid: this.props.issue.issueUid,
        time_added: Date.now(),
        user_email: this.props.currentUserProps.email,
        user_uid: this.props.currentUserProps.uid,
        user_username: this.props.currentUserProps.username
      });

      minusOne(this.props.issue.issueUid);
      this.props.increase(this.props.issue.issueUid);
      if (this.props.currentUserProps.uid) {
        vote(this.props.currentUserProps.uid, this.props.issue.issueUid, false);
      }
    }
  }

  hideShare() {
    this.setState({
      sharing: "hidden"
    });
  }
  showShare() {
    this.setState({
      sharing: "shown"
    });
  }

  renderVote = (theUserVote, theCompany) => {
    if (theUserVote === undefined) {
      return (
        <div className="issue-card-button-div">
          <button
            className="button issue-card-buttons"
            style={{
              color: "white",
              backgroundColor: "#EE4646"
            }}
            onClick={() => this.handleMinus()}
          >
            Unsustain
          </button>

          <button
            className="button issue-card-buttons"
            style={{
              color: "white",
              backgroundColor: "#03AE7A"
            }}
            onClick={() => this.handleAdd()}
          >
            Sustain
          </button>
        </div>
      );
    }
    if (this.state.userVote === true || theUserVote.vote === true) {
      return (
        <div className="issue-card-button-div">
          <button
            className="button issue-card-buttons"
            style={{
              color: "white",
              backgroundColor: "#EE4646 "
            }}
            onClick={this.handleMinus}
          >
            Unsustain
          </button>

          <button
            className="button issue-card-buttons"
            style={{
              color: "white",
              backgroundColor: "gray"
            }}
            onClick={this.handleAdd}
          >
            Sustain
          </button>
        </div>
      );
    }
    if (this.state.userVote === false || theUserVote.vote === false) {
      return (
        <div className="issue-card-button-div">
          <button
            className="button issue-card-buttons"
            style={{
              color: "white",
              backgroundColor: "gray"
            }}
            onClick={this.handleMinus}
          >
            Unsustain
          </button>

          <button
            className="button issue-card-buttons"
            style={{
              color: "white",
              backgroundColor: "#03AE7A"
            }}
            onClick={this.handleAdd}
          >
            Sustain
          </button>
        </div>
      );
    }

    if (this.state.userVote === null) {
      return (
        <div className="issue-card-button-div">
          <button
            className="button issue-card-buttons"
            style={{
              color: "white",
              backgroundColor: "#EE4646 "
            }}
            onClick={this.handleMinus}
          >
            Unsustain
          </button>

          <button
            className="button issue-card-buttons"
            style={{
              color: "white",
              backgroundColor: "#03AE7A"
            }}
            onClick={this.handleAdd}
          >
            Sustain
          </button>
        </div>
      );
    }
  };
  render() {
    // if (this.state.plusCount > 5) {
    //   window.alert("NICE WORK");
    // }
    // const { issue } = this.props;
    let theUserVote = {};
    // let cardColorClass = "";

    // if (theUserVote.vote) {
    //   theUserVote.true
    //     ? (cardColorClass = "greenClass")
    //     : (cardColorClass = "redClass");
    // }

    // const companyKey = this.props.issue.companyKey;

    let theCompany = this.props.allCompanies.filter(
      company => company["companyUid"] === this.props.issue.companyKey
    );
    let userVoteRender = false;
    if (this.props.currentUserProps && this.props.currentUserProps.vote) {
      theUserVote = this.props.currentUserProps.vote[
        `${this.props.issue.issueUid}`
      ];
      userVoteRender = this.props.currentUserProps.vote[
        `${this.props.issue.issueUid}`
      ];
      if (theUserVote === undefined) {
        theUserVote = {};
      }
    }

    let stockTicker;
    let companyName;

    if (theCompany[0] !== undefined) {
      stockTicker = theCompany[0]["stock"];
      companyName = theCompany[0]["name"];
    }

    let stockPrice;
    if (
      theCompany[0] !== undefined &&
      this.props.allStocks[`${stockTicker}`] !== undefined
    ) {
      let stockPriceFirst = this.props.allStocks[`${stockTicker}`]
        .lastSalePrice;
      stockPrice = stockPriceFirst.toFixed(2);
    }

    if (this.state.company === "" && theCompany[0] !== undefined) {
      this.setState({
        company: companyName
      });
    }

    return (
      <Fragment>
        <div className="card" style={styles.profileComponentStyle}>
          <div className="card-content" style={styles.cardContentStyle}>
            <div className="field">
              {(this.props.issue.issuePhoto && (
                <div className="issue-card-image-div">
                  <div className="issue-card-button-over-image">
                    <ul className="issue-card-button-over-image-ul">
                      {stockTicker && (
                        <li className="issue-card-button-over-image-li">
                          <Link
                            to={`/company/${this.props.issue.companyKey}`}
                            style={styles.linkWhiteStyle}
                          >
                            {companyName}
                          </Link>
                          &nbsp; &nbsp;
                          {stockTicker}
                        </li>
                      )}
                      <li
                        className="issue-card-button-over-image-li"
                        id="stock-price"
                      >
                        {stockPrice}
                      </li>
                      {/*  <li
                                className="issue-card-button-over-image-li"
                                id="stock-change"
                              >
                           + 0.138
                              </li>*/}
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={this.props.issue.citation}
                      >
                        <FontAwesome
                          name="fas fa-external-link"
                          className="issue-card-button-retweet-icon"
                        />
                      </a>
                      <li />
                    </ul>
                  </div>
                  <img
                    src={this.props.issue.issuePhoto}
                    className="issue-card-image"
                    alt={`a reflection of ${this.props.issue.title}`}
                  />
                  {/* <div className={this.state.sharing}>
                      <ul className="shareIcons">
                        <li>
                          <FacebookShareButton
                            url={`https://sustain.netlify.com/issue/${
                              this.props.issue.issueUid
                            }`}
                            quote={this.props.issue.title}
                          >
                            <FontAwesome name="fab fa-facebook-f facebook-blue fa-2x" />
                          </FacebookShareButton>
                        </li>
                        <li>
                          <TwitterShareButton
                            size={32}
                            round={true}
                            title={this.props.issue.title}
                            url={`https://sustain.netlify.com/issue/${
                              this.props.issue.issueUid
                            }`}
                          >
                            <FontAwesome name="fab fa-twitter twitter-blue fa-2x" />
                          </TwitterShareButton>
                        </li>
                      </ul>
                    </div> */}
                  {/* <div className="twitter-share-div">
                   <a onClick={this.showShare}> 
                    <TwitterShareButton
                      size={32}
                      round={true}
                      title={`${this.props.issue.title} - @sustain_co`}
                      url={`https://sustain.netlify.com/issue/${
                        this.props.issue.issueUid
                      }`}
                      className="twitter-share-button"
                    >
                      <FontAwesome
                        name="fas fa-share fa-md"
                        className="issue-card-button-share-icon"
                      />
                    </TwitterShareButton>}/}
                  </div>
                 </a> */}
                </div>
              )) ||
                this.props.issue.title}
              {/* </Link> */}
              <br />
              {this.props.currentIssueId === this.props.issue.issueUid && (
                <Confetti active={this.props.confetti} config={config} />
              )}
              <h3>
                {" "}
                <b>{this.props.issue.title}</b>
              </h3>
              <h5>{this.props.issue.pTag}</h5>
            </div>

            <div className="field">
              <p className="issue-card-paragraph">
                {this.props.issue.overImageSentence}
              </p>
            </div>

            <button
              onClick={this.toggleSummaryClassVisible}
              className={`button border-none ${this.state.readMore}`}
            >
              <p id="issueCard-button-read">Read More </p>
            </button>
            <div className="field">
              <div className={this.state.summaryClass}>
                <p className="issue-card-paragraph">
                  {/* var Parser = require('html-react-parser'); */}
                  {Parser(this.props.issue.summary)}
                </p>
              </div>
            </div>
            <button
              onClick={this.toggleSummaryClassHidden}
              className={`button border-none ${this.state.seeLess}`}
            >
              <p id="issueCard-button-read">See Less</p>
            </button>
            {this.renderVote(theUserVote, theCompany)}
            <br />
            {(this.state.userVote !== null || userVoteRender) && (
              <div>
                <VoteResults
                  plus={this.state.plusCount}
                  minus={this.state.minusCount}
                  userVote={theUserVote}
                />
                <div className="field">
                  <Comments
                    item={this.props.issue}
                    currentTheUserVote={theUserVote}
                    currentTheUserStateVote={this.state.userVote}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const styles = {
  profileComponentStyle: {
    padding: 0,
    marginBottom: 20,
    // borderWidth: 10,
    borderRadius: 4
    // backgroundImage: `url(${Background})`
  },
  unorderedListStyle: {
    listStyle: "none"
  },
  cardHeaderStyle: {
    // backgroundColor: "#003533",
    backgroundColor: "#009933",
    color: "white",
    padding: 3,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingLeft: 25,
    margin: 0
  },
  cardContentStyle: {
    padding: "20px",
    margin: 0,
    boxShadow: "2px 5px 15px 0px rgba(0, 0, 0, 0.1)"
  },
  linkWhiteStyle: {
    textDecoration: "none",
    color: "white",
    cursor: "pointer"
  },
  segmentStyle: {
    // backgroundImage: `url(${Background})`,
    borderWidth: 0
  },
  divStyle: {
    height: 7,
    backgroundImage: "#FFDF00"
  }
};
const mapStateToProps = state => ({
  allIssues: state.issues.currentIssuesProps,
  allCompanies: state.companies.currentCompaniesProps,
  allStocks: state.stocks.currentStockProps,
  currentUserProps: state.user.currentUserProps
});

export default connect(mapStateToProps)(IssueCard);
