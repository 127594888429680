import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { storageRef } from "../../firebase/firebase";
import uuidv4 from "uuid/v4";
import ReactQuill from "react-quill";
import { editIssue } from "../../actions/issues";
// import { getAllIssues } from "../../actions/issues";
// import { getAllCompanies } from "../../actions/companyActions";
import "react-quill/dist/quill.snow.css";
class EditIssue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      summary: "",
      company: "",
      // content: "",
      // editorState: editorStateFromRaw(null),
      p: "",
      sentence: "",
      sourceLink: "",
      issuePhoto: "",
      //   value: "",
      environmental: false,
      international: false,
      social: false,
      tech: false,
      redirect: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    // this.handleFileSelect = this.handleFileSelect.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.onChange = editorState => this.setState({ editorState });
  }

  componentDidMount() {
    // getAllCompanies();
    // getAllIssues();
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };
  handleQuillChange = value => {
    this.setState({ summary: value });
  };

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleImage(e) {
    let storeKey = uuidv4();
    const file = e.target.files[0];
    const filename = e.target.files[0].name;
    const profileTypeStorageRef = storageRef.child("pivotal-issue/");
    const profileNameStorageRef = profileTypeStorageRef.child(storeKey);
    const fileURL = profileNameStorageRef.child(filename);
    var uploadTask = fileURL.put(file);
    uploadTask.on("state_changed", snapshot => {
      snapshot.ref.getDownloadURL().then(downloadURL => {
        this.setState({ issuePhoto: downloadURL });
      });
    });
  }
  // handleFileSelect(e) {
  //   this.setState({
  //     file: e.target.files[0]
  //   });
  // }
  // handleImageSelect(e) {
  //   this.setState(
  //     {
  //       featuredImage: e.target.files[0]
  //     },
  //     function() {
  //       console.log(this.state.featuredImage.name + " Featured Image");
  //     }
  //   );
  // }

  // onChange = editorState => {
  //   this.setState({ editorState });
  // };

  handleSubmit(theIssue) {
    let updatedTitle;
    if (this.state.title) {
      updatedTitle = this.state.title;
    } else {
      updatedTitle = theIssue.title;
    }

    let updatedSummary;
    if (this.state.summary) {
      updatedSummary = this.state.summary;
    } else {
      updatedSummary = theIssue.summary;
    }
    let updatedCompany;
    if (this.state.company) {
      updatedCompany = this.state.company;
    } else {
      updatedCompany = theIssue.companyKey;
    }
    let updatedP;
    if (this.state.p) {
      updatedP = this.state.p;
    } else {
      updatedP = theIssue.pTag;
    }
    let updatedSentence;
    if (this.state.sentence) {
      updatedSentence = this.state.sentence;
    } else {
      updatedSentence = theIssue.overImageSentence;
    }
    let updatedSourceLink;
    if (this.state.sourceLink) {
      updatedSourceLink = this.state.sourceLink;
    } else {
      updatedSourceLink = theIssue.citation;
    }
    let updatedIssuePhoto;
    if (this.state.issuePhoto) {
      updatedIssuePhoto = this.state.issuePhoto;
    } else {
      updatedIssuePhoto = theIssue.issuePhoto;
    }
    editIssue(
      theIssue.issueUid,
      updatedTitle,
      updatedSummary,
      updatedCompany,
      updatedP,
      updatedSentence,
      updatedSourceLink,
      updatedIssuePhoto
    );
    this.setState({
      redirect: true
    });
  }

  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/pivotal-issues" />;
    }
    let result;
    let theIssue;
    let companies;
    let companyOptions;
    if (
      Object.keys(this.props.allIssues).length > 1 &&
      this.props.allCompanies
    ) {
      console.log("Just the one issue " + this.props.match.params.issueId);
      result = this.props.allIssues.filter(
        issue => issue["issueUid"] === this.props.match.params.issueId
      );
      theIssue = result[0];

      let tagsArray = ["environmental", "international", "social", "tech"];

      for (let item of tagsArray) {
        if (theIssue[item]) {
          this.setState({
            [item]: theIssue[item]
          });
        }
      }
      console.log("result " + result[0].title);
      companies = Object.values(this.props.allCompanies);
      companyOptions = companies.map((company, index) => (
        <option key={index} value={company.companyUid}>
          {company.name}
        </option>
      ));
    }

    if (
      this.props.currentUserProps.userType !== "admin" ||
      this.props.authUser === null
    ) {
      return <Redirect to="/" />;
    }

    let updatedSentence;
    if (this.state.sentence) {
      updatedSentence = this.state.sentence;
    } else {
      updatedSentence = theIssue.overImageSentence;
    }
    return (
      <Fragment>
        <div className="card" style={styles.profileComponentStyle}>
          <div className="card-content" style={styles.cardContentStyle}>
            <header className="card-header" style={styles.cardHeaderStyle}>
              Edit this Issue
            </header>
            <div style={styles.divStyle} />
            <div className="card-content" style={styles.segmentStyle}>
              <form id="theUploadForm" style={styles.formStyle}>
                <div className="field">
                  <label>Title</label>
                  <input
                    className="input"
                    placeholder={theIssue.title}
                    name="title"
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="field">
                  <label>Company</label>
                  <select name="company" onChange={this.handleInputChange}>
                    <option key="scratch">
                      {
                        this.props.allCompaniesObj[`${theIssue.companyKey}`][
                          "name"
                        ]
                      }
                    </option>
                    {companyOptions}
                  </select>
                </div>
                <div className="field">
                  <label>4 P Tag</label>
                  <select name="p" onChange={this.handleInputChange}>
                    <option key="nada">{theIssue.pTag} </option>
                    <option key="products" value="Products">
                      {" "}
                      Products
                    </option>
                    <option key="people" value="People">
                      {" "}
                      People
                    </option>
                    <option key="procurement" value="Procurement">
                      {" "}
                      Procurement
                    </option>
                    <option key="practices" value="Practices">
                      {" "}
                      Practices
                    </option>
                  </select>
                </div>
                <Fragment>
                  <input
                    style={{ marginRight: "10px" }}
                    name="environmental"
                    type="checkbox"
                    checked={this.state.environmental}
                    onChange={this.handleChange}
                  />
                  <label>Environmental issue ?</label>
                </Fragment>
                <br />
                <Fragment>
                  <input
                    style={{ marginRight: "10px" }}
                    name="international"
                    type="checkbox"
                    checked={this.state.international}
                    onChange={this.handleChange}
                  />
                  <label>International issue ?</label>
                </Fragment>
                <br />
                <Fragment>
                  <input
                    style={{ marginRight: "10px" }}
                    name="social"
                    type="checkbox"
                    checked={this.state.social}
                    onChange={this.handleChange}
                  />
                  <label>Social issue ?</label>
                </Fragment>
                <br />
                <Fragment>
                  <input
                    style={{ marginRight: "10px" }}
                    name="tech"
                    type="checkbox"
                    checked={this.state.tech}
                    onChange={this.handleChange}
                  />
                  <label>Tech issue ?</label>
                </Fragment>
                <div className="field">
                  <label>Sentence or 2 for over image</label>
                  <textarea
                    className="textarea"
                    placeholder={updatedSentence}
                    onChange={this.handleInputChange}
                    name="sentence"
                  />
                </div>
                <div className="field">
                  <label>Summary</label>
                  <ReactQuill
                    placeholder={theIssue.summary}
                    value={this.state.summary}
                    onChange={this.handleQuillChange}
                    theme="snow"
                    style={{ minHeight: "500px" }}
                  />
                  {/* <textarea
                    className="textarea"
                    placeholder={theIssue.summary}
                    onChange={this.handleInputChange}
                    name="summary"
                  /> */}
                </div>

                <div className="field">
                  <label>Image Upload: </label>
                  <input
                    className="input"
                    type="file"
                    onChange={this.handleImage}
                    name="issuePhoto"
                  />
                  {theIssue.issuePhoto && (
                    <div>
                      <h1> Current Photo:</h1>
                      <img
                        src={theIssue.issuePhoto}
                        width="250px"
                        alt={`A reflection of ${theIssue.title}`}
                      />
                    </div>
                  )}
                  {this.state.issuePhoto && (
                    <img
                      src={this.state.issuePhoto}
                      width="250px"
                      alt={`A reflection of ${this.state.title}`}
                    />
                  )}
                </div>
                <div className="field">
                  <label>Cite Source Link</label>
                  <input
                    className="input"
                    placeholder={theIssue.citation}
                    onChange={this.handleInputChange}
                    name="sourceLink"
                  />
                </div>

                <button
                  className="button is-primary"
                  onClick={() => this.handleSubmit(theIssue)}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        <br />
        <br />
      </Fragment>
    );
  }
}

const styles = {
  profileComponentStyle: {
    marginBottom: 10,
    borderWidth: 0,
    boxShadow: "none",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
    // backgroundImage: `url(${Background})`
  },
  cardHeaderStyle: {
    // backgroundColor: "#003533",
    backgroundColor: "#D3D3D3",
    color: "white",
    padding: 3,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    paddingLeft: 25,
    margin: 0,
    boxShadow: "none"
  },
  cardContentStyle: {
    padding: 0,
    margin: 0
  },
  segmentStyle: {
    boxShadow: "none",
    borderWidth: 0
    // backgroundImage: `url(${Background})`
  },
  divStyle: {
    height: 7,
    backgroundImage: "#FFDF00"
  }
};

const mapStateToProps = state => ({
  allIssues: state.issues.currentIssuesProps,
  allCompanies: state.companies.currentCompaniesProps,
  allCompaniesObj: state.companies.allCompaniesObject,
  currentUserProps: state.user.currentUserProps,
  authUser: state.session.authUser
});

export default connect(mapStateToProps)(EditIssue);

/* <Form.Field>
                  <label>Category</label>
                  <Dropdown
                    selection
                    name="Category"
                    fluid
                    search
                    options={dropDownOptions}
                    label="Category"
                    placeholder="Category"
                    onChange={this.handleReactSelectChange}
                  />
                </Form.Field>  <Form.Field>
                  <label>Affect Tag</label>
                  <Select
                    multi
                    options={tagOptions}
                    placeholder="Who does this affect?"
                    name="affectTag"
                    onChange={this.handleTagSelect}
                    value={this.state.tags}
                  />
                </Form.Field>  <Form.Field>
                  <label htmlFor="">File</label>
                  <input
                    type="file"
                    name="file"
                    id="exampleFile"
                    onChange={this.handleFileSelect}
                  />
                </Form.Field>  <Form.Field>
                  <label htmlFor="">Video Embed</label>
                  <Input
                    label="https://"
                    placeholder="paste youtube/vimeo url or planet 9 embed code"
                    name="videoUrl"
                    onChange={this.handleInputChange}
                  />
                </Form.Field> <Form.Field>
                  <label htmlFor="featuredImage">
                    Featured Image - not working yet
                  </label>
                  <input
                    type="file"
                    name="featuredImage"
                    id="featuredImage"
                    onChange={this.handleImageSelect}
                  />
                </Form.Field> <Form.Field>
                  <label>Content</label>
                  <Segment style={{ minHeight: 100 }}>
                    <MegadraftEditor
                      editorState={this.state.editorState}
                      onChange={this.onChange}
                    />
                  </Segment>
                </Form.Field> */
