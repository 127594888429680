import { db } from "./firebase";
// import * as firebase from "./firebase";

export const doCreateUser = (id, email, username, firstName, lastName) => {
  db.ref(`users/${id}`).set({
    email,
    firstName,
    lastName,
    profilePhoto: "",
    status: "newUser",
    uid: id,
    userType: "guest",
    username
  });
};

export const onceGetUsers = () => db.ref("users").once("value");
