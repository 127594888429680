import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";

import { addCompany } from "../../actions/companyActions";

class AddCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: "",
      stockSymbol: "",
      redirectToNewPage: false
      //   value: "",
      //   loaderState: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    // this.handleFileSelect = this.handleFileSelect.bind(this);
    this.handleImageSelect = this.handleImageSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = editorState => this.setState({ editorState });
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleFileSelect(e) {
    this.setState({
      file: e.target.files[0]
    });
  }
  handleImageSelect(e) {
    this.setState(
      {
        featuredImage: e.target.files[0]
      },
      function() {
        console.log(this.state.featuredImage.name + " Featured Image");
      }
    );
  }

  onChange = editorState => {
    this.setState({ editorState });
  };

  handleSubmit() {
    // const contentAsHtml = stateToHTML(
    //   this.state.editorState.getCurrentContent()
    // );

    addCompany(
      this.state.companyName,
      this.state.stockSymbol
      // , contentAsHtml
    );

    document.getElementById("theUploadForm").reset();
    this.setState({
      redirectToNewPage: true
    });
  }

  render() {
    if (this.state.redirectToNewPage) {
      return <Redirect to="/" />;
    }

    if (
      this.props.authUser === null ||
      this.props.currentUserProps.userType !== "admin"
    ) {
      return <Redirect to="/" />;
    }
    return (
      <Fragment>
        <div className="card" style={styles.profileComponentStyle}>
          <div className="card-content" style={styles.cardContentStyle}>
            <header className="card-header" style={styles.cardHeaderStyle}>
              Add a Company
            </header>
            <div style={styles.divStyle} />
            <div className="card-content" style={styles.segmentStyle}>
              <form
                id="theUploadForm"
                onSubmit={this.handleSubmit}
                style={styles.formStyle}
              >
                <div className="field">
                  <label>Company Name</label>
                  <input
                    placeholder="Amazon"
                    name="companyName"
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="field">
                  <label>Stock Symbol</label>
                  <input
                    placeholder="AMZN"
                    onChange={this.handleInputChange}
                    name="stockSymbol"
                  />
                </div>
                {/* <Form.Field>
                  <label>Category</label>
                  <Dropdown
                    selection
                    name="Category"
                    fluid
                    search
                    options={dropDownOptions}
                    label="Category"
                    placeholder="Category"
                    onChange={this.handleReactSelectChange}
                  />
                </Form.Field> */}
                {/* <Form.Field>
                  <label>Affect Tag</label>
                  <Select
                    multi
                    options={tagOptions}
                    placeholder="Who does this affect?"
                    name="affectTag"
                    onChange={this.handleTagSelect}
                    value={this.state.tags}
                  />
                </Form.Field> */}
                {/* <Form.Field>
                  <label htmlFor="">File</label>
                  <input
                    type="file"
                    name="file"
                    id="exampleFile"
                    onChange={this.handleFileSelect}
                  />
                </Form.Field> */}
                {/* <Form.Field>
                  <label htmlFor="">Video Embed</label>
                  <Input
                    label="https://"
                    placeholder="paste youtube/vimeo url or planet 9 embed code"
                    name="videoUrl"
                    onChange={this.handleInputChange}
                  />
                </Form.Field> */}
                {/* <Form.Field>
                  <label htmlFor="featuredImage">
                    Featured Image - not working yet
                  </label>
                  <input
                    type="file"
                    name="featuredImage"
                    id="featuredImage"
                    onChange={this.handleImageSelect}
                  />
                </Form.Field> */}
                {/* <Form.Field>
                  <label>Content</label>
                  <Segment style={{ minHeight: 100 }}>
                    <MegadraftEditor
                      editorState={this.state.editorState}
                      onChange={this.onChange}
                    />
                  </Segment>
                </Form.Field> */}
                <button
                  className="button is-info is-outlined"
                  onClick={this.handleSubmit}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const styles = {
  profileComponentStyle: {
    marginBottom: 10,
    borderWidth: 0,
    boxShadow: "none",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
    // backgroundImage: `url(${Background})`
  },
  cardHeaderStyle: {
    // backgroundColor: "#003533",
    backgroundColor: "#D3D3D3",
    color: "white",
    padding: 3,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingLeft: 25,
    margin: 0,
    boxShadow: "none"
  },
  cardContentStyle: {
    padding: 0,
    margin: 0
  },
  segmentStyle: {
    boxShadow: "none",
    borderWidth: 0
    // backgroundImage: `url(${Background})`
  },
  divStyle: {
    height: 7,
    backgroundImage: "#FFDF00"
  }
};

const mapStateToProps = state => ({
  allIssues: state.issues.currentIssuesProps,
  currentUserProps: state.user.currentUserProps,
  authUser: state.session.authUser
});

export default connect(mapStateToProps)(AddCompany);
