import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { storageRef } from "../../firebase/firebase";
import ReactQuill from "react-quill";
import uuidv4 from "uuid/v4";
import { getAllCompanies } from "../../actions/companyActions";
import { addIssue } from "../../actions/issues";
import "./styles.css";
import "react-quill/dist/quill.snow.css";

class AddIssue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      environmental: false,
      environmentalRange: 0,
      corporate: false,
      corporateRange: 0,
      international: false,
      internationalRange: 0,
      social: false,
      socialRange: 0,
      tech: false,
      techRange: 0,
      title: "",
      summary: "",
      companyKey: "",
      // content: "",
      pTag: "",
      overImageSentence: "",
      citation: "",
      issuePhoto: "",
      //   value: "",
      redirect: false
    };
  }

  componentDidMount() {
    getAllCompanies();
  }
  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleQuillChange = value => {
    this.setState({ summary: value });
  };

  handleImage = e => {
    let storeKey = uuidv4();
    const file = e.target.files[0];
    const filename = e.target.files[0].name;
    const profileTypeStorageRef = storageRef.child("pivotal-issue/");
    const profileNameStorageRef = profileTypeStorageRef.child(storeKey);
    const fileURL = profileNameStorageRef.child(filename);
    fileURL.put(file).then(snapshot => {
      snapshot.ref.getDownloadURL().then(downloadURL => {
        this.setState({ issuePhoto: downloadURL });
      });
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    let stateArray = Object.keys(this.state);
    // prevents form from uploading empty values
    let formObj = {};
    for (let key of stateArray) {
      if (key !== "redirect" || !key.includes("Range")) {
        let value = this.state[`${key}`];
        if (value === "" || value.length === 0) {
          window.alert("Please add " + key + " before submitting");
          return;
        }
        formObj[key] = this.state[key];
      }
    }
    addIssue(formObj);

    document.getElementById("theUploadForm").reset();
    this.setState({
      title: "",
      summary: "",
      companyKey: "",
      corporate: false,
      // content: "",
      pTag: "",
      overImageSentence: "",
      citation: "",
      issuePhoto: "",
      redirect: true,
      environmental: false,
      international: false,
      social: false,
      tech: false
    });
  };

  renderCompanies = () => {
    function compare(a, b) {
      // Use toUpperCase() to ignore character casing
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      let comparison = 0;
      if (nameA > nameB) {
        comparison = 1;
      } else if (nameA < nameB) {
        comparison = -1;
      }
      return comparison;
    }
    let companies = Object.values(this.props.allCompanies);
    let inOrder = companies.sort(compare);
    return inOrder.map((company, index) => (
      <option key={index} value={company.companyUid}>
        {company.name}
      </option>
    ));
  };
  render() {
    if (
      this.props.authUser === null ||
      this.props.currentUserProps.userType !== "admin"
    ) {
      return <Redirect to="/" />;
    }
    return (
      <Fragment>
        <div className="card" style={styles.profileComponentStyle}>
          <div className="card-content" style={styles.cardContentStyle}>
            <header className="card-header" style={styles.cardHeaderStyle}>
              Add New Issue
            </header>
            <div style={styles.divStyle} />
            <div className="card-content" style={styles.segmentStyle}>
              <form
                id="theUploadForm"
                onSubmit={e => this.handleSubmit(e)}
                style={styles.formStyle}
              >
                <div className="field">
                  <label>Title</label>
                  <input
                    className="input"
                    placeholder="Title"
                    name="title"
                    onChange={this.handleInputChange}
                    value={this.state.title}
                  />
                </div>
                <div className="field">
                  <label>Company</label>
                  <div className="select">
                    <select name="companyKey" onChange={this.handleInputChange}>
                      <option />
                      {this.renderCompanies(this.props.allCompanies)}
                    </select>
                  </div>
                </div>
                <div className="field">
                  <label>4 P Tag</label>
                  <div className="select">
                    <select name="pTag" onChange={this.handleInputChange}>
                      <option />
                      <option key="products" value="Products">
                        {" "}
                        Products
                      </option>
                      <option key="people" value="People">
                        {" "}
                        People
                      </option>
                      <option key="procurement" value="Procurement">
                        {" "}
                        Procurement
                      </option>
                      <option key="practices" value="Practices">
                        {" "}
                        Practices
                      </option>
                    </select>
                  </div>
                </div>
                <Fragment>
                  <input
                    style={{ marginRight: "10px" }}
                    name="environmental"
                    type="checkbox"
                    checked={this.state.environmental}
                    onChange={this.handleChange}
                  />
                  <label style={{ marginRight: "10px" }}>
                    Environmental issue ?
                  </label>
                </Fragment>
                {this.state.environmental && (
                  <Fragment>
                    <input
                      type="range"
                      name="environmentalRange"
                      value={this.state.environmentalRange}
                      min="0"
                      max="5"
                      step="1"
                      onChange={this.handleChange}
                    />
                    <span class="range-slider__value">
                      {this.state.environmentalRange}
                    </span>
                  </Fragment>
                )}
                <br />
                <Fragment>
                  <input
                    style={{ marginRight: "10px" }}
                    name="corporate"
                    type="checkbox"
                    checked={this.state.corporate}
                    onChange={this.handleChange}
                  />
                  <label style={{ marginRight: "10px" }}>
                    Corporate issue ?
                  </label>
                </Fragment>
                {this.state.corporate && (
                  <Fragment>
                    <input
                      type="range"
                      name="corporateRange"
                      value={this.state.corporateRange}
                      min="0"
                      max="5"
                      step="1"
                      onChange={this.handleChange}
                    />
                    <span class="range-slider__value">
                      {this.state.corporateRange}
                    </span>
                  </Fragment>
                )}
                <br />
                <Fragment>
                  <input
                    style={{ marginRight: "10px" }}
                    name="international"
                    type="checkbox"
                    checked={this.state.international}
                    onChange={this.handleChange}
                  />
                  <label style={{ marginRight: "10px" }}>
                    International issue ?
                  </label>
                </Fragment>
                {this.state.international && (
                  <Fragment>
                    <input
                      type="range"
                      name="internationalRange"
                      value={this.state.internationalRange}
                      min="0"
                      max="5"
                      step="1"
                      onChange={this.handleChange}
                    />
                    <span class="range-slider__value">
                      {this.state.internationalRange}
                    </span>
                  </Fragment>
                )}
                <br />
                <Fragment>
                  <input
                    style={{ marginRight: "10px" }}
                    name="social"
                    type="checkbox"
                    checked={this.state.social}
                    onChange={this.handleChange}
                  />
                  <label style={{ marginRight: "10px" }}>Social issue ?</label>
                </Fragment>
                {this.state.social && (
                  <Fragment>
                    <input
                      type="range"
                      name="socialRange"
                      value={this.state.socialRange}
                      min="0"
                      max="5"
                      step="1"
                      onChange={this.handleChange}
                    />
                    <span class="range-slider__value">
                      {this.state.socialRange}
                    </span>
                  </Fragment>
                )}
                <br />
                <Fragment>
                  <input
                    style={{ marginRight: "10px" }}
                    name="tech"
                    type="checkbox"
                    checked={this.state.tech}
                    onChange={this.handleChange}
                  />
                  <label style={{ marginRight: "10px" }}>Tech issue ?</label>
                </Fragment>
                {this.state.tech && (
                  <Fragment>
                    <input
                      type="range"
                      name="techRange"
                      value={this.state.techRange}
                      min="0"
                      max="5"
                      step="1"
                      onChange={this.handleChange}
                    />
                    <span class="range-slider__value">
                      {this.state.techRange}
                    </span>
                  </Fragment>
                )}
                <div className="field">
                  <label>Sentence or 2 for over image</label>
                  <input
                    className="input"
                    placeholder="Sentence or 2"
                    onChange={this.handleInputChange}
                    name="overImageSentence"
                    value={this.state.overImageSentence}
                  />
                </div>
                <div className="field">
                  <label>Summary</label>
                  <ReactQuill
                    value={this.state.summary}
                    onChange={this.handleQuillChange}
                    theme="snow"
                  />
                  {/* <textarea
                    className="textarea"
                    placeholder="Summary"
                    onChange={this.handleInputChange}
                    name="summary"
                    rows={4}
                    value={this.state.summary}
                  /> */}
                </div>
                <div className="field">
                  <label>Image Upload: </label>
                  <input
                    className="input"
                    type="file"
                    onChange={this.handleImage}
                    name="issuePhoto"
                  />
                  {this.state.issuePhoto && (
                    <img
                      src={this.state.issuePhoto}
                      width="250px"
                      alt={`A reflection of this.state.title`}
                    />
                  )}
                </div>
                <div className="field">
                  <label>Cite Source Link</label>
                  <input
                    className="input"
                    placeholder="link to source"
                    onChange={this.handleInputChange}
                    name="citation"
                    value={this.state.citation}
                  />
                </div>

                {/* <Form.Field>
                  <label>Category</label>
                  <Dropdown
                    selection
                    name="Category"
                    
                    search
                    options={dropDownOptions}
                    label="Category"
                    placeholder="Category"
                    onChange={this.handleReactSelectChange}
                  />
                </Form.Field> */}
                {/* <Form.Field>
                  <label>Affect Tag</label>
                  <Select
                    multi
                    options={tagOptions}
                    placeholder="Who does this affect?"
                    name="affectTag"
                    onChange={this.handleTagSelect}
                    value={this.state.tags}
                  />
                </Form.Field> */}
                {/* <Form.Field>
                  <label htmlFor="">File</label>
                  <input
                    type="file"
                    name="file"
                    id="exampleFile"
                    onChange={this.handleFileSelect}
                  />
                </Form.Field> */}
                {/* <Form.Field>
                  <label htmlFor="">Video Embed</label>
                  <Input
                    label="https://"
                    placeholder="paste youtube/vimeo url or planet 9 embed code"
                    name="videoUrl"
                    onChange={this.handleInputChange}
                  />
                </Form.Field> */}
                {/* <Form.Field>
                  <label htmlFor="featuredImage">
                    Featured Image - not working yet
                  </label>
                  <input
                    type="file"
                    name="featuredImage"
                    id="featuredImage"
                    onChange={this.handleImageSelect}
                  />
                </Form.Field> */}
                {/* <Form.Field>
                  <label>Content</label>
                  <Segment style={{ minHeight: 100 }}>
                    <MegadraftEditor
                      editorState={this.state.editorState}
                      onChange={this.onChange}
                    />
                  </Segment>
                </Form.Field> */}
                <button
                  className="button is-info is-outlined"
                  style={styles.buttonStyle}
                  onClick={this.handleSubmit}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
          <br />
          <br />
        </div>
      </Fragment>
    );
  }
}

const styles = {
  profileComponentStyle: {
    marginBottom: 10,
    borderWidth: 0,
    boxShadow: "none",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
    // backgroundImage: `url(${Background})`
  },
  cardHeaderStyle: {
    // backgroundColor: "#003533",
    backgroundColor: "#D3D3D3",
    color: "white",
    padding: 3,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    paddingLeft: 25,
    margin: 0,
    boxShadow: "none"
  },
  cardContentStyle: {
    padding: 0,
    margin: 0
  },
  segmentStyle: {
    boxShadow: "none",
    borderWidth: 0
    // backgroundImage: `url(${Background})`
  },
  divStyle: {
    height: 7,
    backgroundImage: "#FFDF00"
  }
};

const mapStateToProps = state => ({
  allIssues: state.issues.currentIssuesProps,
  allCompanies: state.companies.currentCompaniesProps,
  currentUserProps: state.user.currentUserProps,
  authUser: state.session.authUser
});

export default connect(mapStateToProps)(AddIssue);
