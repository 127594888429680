import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import EngageCard from "./Engage/EngageCard";
// import EngageUserCard from "./Engage/EngageUserCard";
import EngageUserCardType2 from "./Engage/EngageUserCardType2";

// import { checkStockPrices } from "../actions/stockActions";

class Engage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.renderVoters = this.renderVoters.bind(this);
    // this.onExpand = this.onExpand.bind(this);
    // this.toggleVisibility = this.toggleVisibility.bind(this);

    this.renderAllUserVotes = this.renderAllUserVotes.bind(this);
  }

  // onExpand(id) {
  //   issueVar = show;
  // }
  // toggleVisibility = () => this.setState({ visible: !this.state.visible });

  // compare = (firstArray, secondArray) => {
  //   let finalVoteResults = firstArray.filter(
  //     e1 => !secondArray.find(e2 => e1.issueUid === e2.issueUid)
  //   );

  //   return finalVoteResults;
  // };

  // compareTwo = (firstArray, secondArray) => {
  //   let finalArray = [];
  //   firstArray.forEach(e1 =>
  //     secondArray.forEach(e2 => {
  //       if (e1.issueUid === e2) {
  //         finalArray.push(e1);
  //       }
  //     })
  //   );
  //   return finalArray;
  // };

  renderAllUserVotes(issues, userProps) {
    let sliceArray = [];
    if (userProps !== undefined && userProps.vote !== undefined && issues) {
      // let userVotes = Object.values(userProps.vote);
      // let userKeys = Object.keys(userProps.vote);

      // let voteResultsArray = this.compare(userVotes, this.props.allIssues);
      // let reversed = voteResultsArray.reverse();
      // let userIssuesArray = this.compareTwo(this.props.allIssues, userKeys);
      let resultsArray = [];
      // let newArray = userIssuesArray.map((issue, index) => {
      //   resultsArray[`${index}`] = Object.assign(issue, reversed[`${index}`]);
      // });
      sliceArray = resultsArray.slice(0, 5);
      return sliceArray.map((issue, index) => {
        return (
          // <li key={issue.issueUid} className="engage-card-parent-li">
          <EngageCard
            key={issue.issueUid}
            issue={issue}
            userProps={userProps}
            commentUser={this.props.authUser}
            allUsers={this.props.allUsers}
          />
          // </li>
        );
      });
    }
  }
  renderVoters(users) {
    return users.map(user => (
      // <li className="engage-list" key={user.uid}>
      <li className="engage-list-type2" key={user.uid}>
        <EngageUserCardType2
          user={user}
          allIssues={this.props.allIssues}
          renderAllUserVotes={this.renderAllUserVotes}
        />
      </li>
    ));
  }

  render() {
    if (this.props.authUser === null) {
      return <Redirect to="/login" />;
    }
    let allUsers = [];
    let usersWithVotes = [];
    let sortedUsersArray = [];
    if (this.props.allUsers !== null) {
      allUsers = Object.values(this.props.allUsers);
      usersWithVotes = allUsers.filter(user => user.vote !== undefined);
      sortedUsersArray = usersWithVotes.sort(function(a, b) {
        return Object.keys(b.vote).length - Object.keys(a.vote).length;
      });
    }

    return (
      <div className="engage-container">
        <h2 className="engage-title">Engagement Leaderboard</h2>
        <ul className="engage-ul-type2">
          {this.renderVoters(sortedUsersArray)}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  allIssues: state.issues.currentIssuesProps,
  allCompanies: state.companies.currentCompaniesProps,
  allUsers: state.user.allUsers,
  authUser: state.session.authUser,
  currentUserProps: state.user.currentUserProps
});

export default connect(mapStateToProps)(Engage);
