import React from "react";

const VoteBar = ({ height, sustain }) => {
  const divChild = {
    backgroundColor:
      sustain === "true" ? "rgb(3, 174, 122)" : "rgb(238, 70, 70)",
    alignSelf: "end",
    height: height * 100,
    width: "100%"
  };
  return <div style={divChild} />;
};

export default VoteBar;
