import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import { auth } from "../firebase/index";
var FontAwesome = require("react-fontawesome");

const FooterNav = props => {
  return (
    <div className="footer-div">
      <ul className="footer-list">
        <Link to="/">
          <li className="footer-list-item">
            <FontAwesome name="fal fa-home fa-lg" className="sustain-blue" />{" "}
            <br />
            <p className="sustain-blue">Home</p>
          </li>
        </Link>
        {props.authUser && props.authUser.uid ? (
          <Link to={`/users/${props.authUser.uid}`}>
            <li className="footer-list-item">
              <FontAwesome name="user fas fa-lg" className="sustain-blue" />
              <p className="sustain-blue">My Profile</p>
            </li>
          </Link>
        ) : (
          <Link to={`/`}>
            <li className="footer-list-item">
              <FontAwesome name="user fas fa-lg" className="sustain-blue" />
              <p className="sustain-blue">My Profile</p>
            </li>
          </Link>
        )}
        <Link to="/engage">
          <li className="footer-list-item">
            <FontAwesome name="fal fa-comment fa-lg" className="sustain-blue" />{" "}
            <br />
            <p className="sustain-blue">Engage</p>
          </li>
        </Link>
        <Link to="/index">
          <li className="footer-list-item">
            <FontAwesome
              name="fal fa-university fa-lg"
              className="sustain-blue"
            />{" "}
            <br />
            <p className="sustain-blue index-button-text">Index</p>
          </li>
        </Link>
      </ul>
    </div>
  );
};

const mapStateToProps = state => ({
  allIssues: state.issues.currentIssuesProps,
  allCompanies: state.companies.currentCompaniesProps,
  currentUserProps: state.user.currentUserProps,
  authUser: state.session.authUser
});

export default connect(mapStateToProps)(FooterNav);
