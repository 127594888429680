import store from "../store";
import { firebase } from "../firebase/firebase";
import { SustainTenArray } from "../components/Constants/index";

const ADD_STOCKS = "ADD_STOCKS";
export function addStocks(data) {
  return {
    type: ADD_STOCKS,
    data
  };
}
const ADD_SUSTAIN_TEN_STOCKS = "ADD_SUSTAIN_TEN_STOCKS";
export function addSustainTenStocks(data) {
  return {
    type: ADD_SUSTAIN_TEN_STOCKS,
    data
  };
}

export const checkStockPrices = () => {
  firebase
    .database()
    .ref("stocks")
    .on("value", snapshot => {
      let data = snapshot.val();
      if (data) {
        store.dispatch(addStocks(data));
        return theSustainTen(data, SustainTenArray);
      }
    });
};

function theSustainTen(stocks, SustainTenArray) {
  let sustainTenObject = {};
  for (let item of SustainTenArray) {
    sustainTenObject[`${item}`] = stocks[`${item}`];
  }
  return store.dispatch(addSustainTenStocks(sustainTenObject));
}
