import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { firebase } from "../../firebase/firebase";
import InfiniteScroll from "react-infinite-scroll-component";
import store from "../../store";
import { getAllUsers, getProfileFromStore } from "../../actions/userActions";
import {
  // getAllIssues,
  setCurrentAllIssuesProps,
  setNextIssuesProps
} from "../../actions/issues";
import ProfileForm from "./ProfileForm";
import UserIssueCard from "./UserIssueCard";
import "./user.css";

var FontAwesome = require("react-fontawesome");
const Loading = require("react-loading-animation");

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      referenceToOldestKey: ""
    };
    this.renderAllUserVotes = this.renderAllUserVotes.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    getAllUsers();
    // this.getUserFromStore();
  }

  getUserFromStore = () => {
    this.props.actions.getProfileFromStore(
      this.props.allUsers,
      this.props.location.pathname
    );
  };
  getIssues = () => {
    // let self = this;
    if (this.state.referenceToOldestKey === "") {
      // if initial fetch

      firebase
        .database()
        .ref("issues")
        .orderByKey()
        .limitToLast(25)
        .once("value")
        .then(snapshot => {
          // changing to reverse chronological order (latest first)
          let arrayOfKeys = Object.keys(snapshot.val())
            .sort()
            .reverse();
          // .reverse();
          // transforming to array
          let results = arrayOfKeys.map(key => snapshot.val()[key]);
          // storing reference
          let oldestKey = arrayOfKeys[arrayOfKeys.length - 1];
          // console.log("oldest key " + oldestKey);
          this.setState({ referenceToOldestKey: oldestKey }, () => {
            store.dispatch(setCurrentAllIssuesProps(results));
          });
        })
        .catch(error => {
          console.log("Error in getIssues " + error);
        });
    } else {
      firebase
        .database()
        .ref("issues")
        .orderByKey()
        .endAt(this.state.referenceToOldestKey)
        .limitToLast(25)
        .once("value")
        .then(snapshot => {
          // changing to reverse chronological order (latest first)
          // & removing duplicate
          let arrayOfKeys = Object.keys(snapshot.val())
            .sort()
            .reverse()
            .slice(1);
          // transforming to array
          let results = arrayOfKeys.map(key => snapshot.val()[key]);
          // updating reference
          let oldestKey = arrayOfKeys[arrayOfKeys.length - 1];
          // Do what you want to do with the data, i.e.
          this.setState({ referenceToOldestKey: oldestKey }, () => {
            store.dispatch(setNextIssuesProps(results));
          });

          // append to page or dispatch({ … }) if using redux
        })
        .catch(error => {
          console.log("Error in 2nd part getIssues " + error);
        });
    }
  };

  checkForUser = () => {
    let pathArray = this.props.location.pathname.split("/");
    pathArray.splice(0, 1);
    let profileName = pathArray[1];
    if (profileName !== this.props.profile.uid) {
      this.props.actions.getProfileFromStore(
        this.props.allUsers,
        this.props.location.pathname
      );
    }
  };
  compareTwo = (firstArray, secondArray) => {
    let finalArray = [];
    firstArray.forEach(e1 =>
      secondArray.forEach(e2 => {
        if (e1.issueUid === e2) {
          finalArray.push(e1);
        }
      })
    );
    return finalArray;
  };
  renderLevel = () => {
    let userLevel = "Activist";
    if (this.props.profile.vote) {
      let voteArray = Object.values(this.props.profile.vote);
      if (voteArray.length < 25) {
        userLevel = "Activist";
      } else if (voteArray.length < 75) {
        userLevel = "Sustainability Officer";
      } else if (voteArray.length < 150) {
        userLevel = "Freedom Fighter";
      } else if (voteArray.length < 250) {
        userLevel = "Impact Guru";
      } else if (voteArray.length >= 250) {
        userLevel = "Sustainability Ninja";
      }
    }
    return userLevel;
  };
  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  renderAllUserVotes(issues, userProps) {
    if (
      userProps !== undefined &&
      userProps.vote !== undefined &&
      issues &&
      this.props.allCompaniesObj !== undefined &&
      Object.values(this.props.allCompaniesObj).length > 1
    ) {
      let userKeys = Object.keys(userProps.vote).reverse();
      // const filteredArray = this.compareTwo(issues, userKeys);
      // let status = userKeys.length === filteredArray.length;
      return (
        <InfiniteScroll
          dataLength={issues.length}
          next={this.getIssues}
          hasMore={true}
          loader={<Loading />}
        >
          {userKeys.map(userVote => {
            return (
              <li key={userVote}>
                <UserIssueCard
                  user={userProps}
                  userVote={userVote}
                  companies={this.props.allCompaniesObj}
                  allIssues={issues}
                  allStocks={this.props.allStocks}
                />
              </li>
            );
          })}
        </InfiniteScroll>
      );
    }
  }

  //No engage leaderboard link

  //Go Vote float near bottom maybe

  //Then hisotry of votes similar to feed

  //collapse of vote history

  //drop down of current engage

  //own user voting history de priority

  //voting history touch to open
  render() {
    let users = Object.keys(this.props.profile);
    if (users.length === 0 && Object.keys(this.props.allUsers).length > 1) {
      this.getUserFromStore();
    }

    const showEditUser =
      this.state.visible === false ? (
        " "
      ) : (
        <div>
          <ProfileForm toggle={this.toggleVisibility} />
        </div>
      );
    return (
      <div>
        {this.props.profile !== undefined ? (
          <div className="card-content" style={styles.bottomStyle}>
            <div className="user-profile-header">
              <div className="image-and-edit">
                <div className="profile-image-container">
                  {!this.props.profile.profilePhoto ? (
                    <FontAwesome name="fal fa-user-circle fa-3x" />
                  ) : (
                    <img
                      src={this.props.profile.profilePhoto}
                      className="profile-image"
                      alt="profile"
                    />
                  )}
                </div>
              </div>
              <div className="field">
                <span className="user-name">
                  {this.props.profile.username
                    ? this.props.profile.username
                    : this.props.profile.name}
                  <br />
                </span>
                <p className="location-name">
                  {this.props.profile.location ? (
                    <span>
                      <FontAwesome name="far fa-map-marker" />
                      {"  "}
                      {this.props.profile.location}
                    </span>
                  ) : (
                    <span />
                  )}
                </p>
              </div>
            </div>
            {this.checkForUser()}
            {!this.props.profile.occupation
              ? ""
              : `Expertise: ${this.props.profile.occupation}`}
            <br />
            <br />
            <p>
              {!this.props.profile.bio
                ? "I define sustainability as ..."
                : this.props.profile.bio}
            </p>
            <br />
            {this.props.profile &&
            this.props.authUser &&
            this.props.profile.uid === this.props.authUser.uid ? (
              <button
                className="profile-edit-button"
                onClick={this.toggleVisibility}
                style={{ marginLeft: "25px", marginBottom: "20px" }}
              >
                {this.state.visible ? "Cancel" : "Edit"}
              </button>
            ) : (
              ""
            )}
            <div>{showEditUser}</div>
            <div>
              <div className="field">
                {/* <label>
                  {this.props.profile.username
                    ? this.props.profile.username
                    : this.props.profile.name}
                  's Activity &nbsp;&nbsp;
                </label>
                <div className="select select is-small">
                  <select>
                    <option value="this month">this month</option>
                    <option value="this year">this year</option>
                  </select>
                </div> */}

                <hr />

                <label>
                  Engagement Level:{" "}
                  <span className="officer-green">{this.renderLevel()}</span>
                </label>
                <hr />
                <Link to="/engage"> Engagement LeaderBoard </Link>
                <hr />
              </div>
            </div>
            <div>
              <ul style={{ listStyle: "none" }}>
                {this.renderAllUserVotes(
                  this.props.allIssues,
                  this.props.profile
                )}
              </ul>
            </div>
          </div>
        ) : (
          <div>
            <Loading />
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  bottomStyle: {
    marginBottom: 100
  }
};
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getProfileFromStore: function(data, pathname) {
        dispatch(getProfileFromStore(data, pathname));
      }
    }
  };
}

const mapStateToProps = state => ({
  allIssues: state.issues.currentIssuesProps,
  allIssuesObject: state.issues.allIssuesObject,
  allCompanies: state.companies.currentCompaniesProps,
  allCompaniesObj: state.companies.allCompaniesObject,
  allStocks: state.stocks.currentStockProps,
  allUsers: state.user.allUsers,
  authUser: state.session.authUser,
  profile: state.user.profileData
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(User);
