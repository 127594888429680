import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import { nextFundSignUp } from "../../actions/userActions";
// import { slide as Menu } from "react-burger-menu";
// import sustain_logo from "../assets/sustain_logo.png";

//// NOT IN USE RIGHT NOW
class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      submitted: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(e) {
    e.preventDefault();
    nextFundSignUp(
      this.props.currentUserProps.email,
      this.props.currentUserProps.uid,
      this.props.currentUserProps.username
    );

    this.setState({ submitted: true });
  }

  render() {
    return (
      <Fragment>
        {this.state.submitted === false ? (
          <div className="card">
            <div className="card-content">
              <div className="card-content">
                <form id="theUploadForm" onSubmit={this.handleSubmit}>
                  <div className="field">
                    <h2>
                      <b>{this.props.currentUserProps.username}</b> we would
                      love to invite you to our next fund.
                    </h2>
                    {/* <input
                      className="input"
                      placeholder="E-mail"
                      name="email"
                      onChange={this.handleInputChange}
                    /> */}
                  </div>

                  <button
                    className="button is-link "
                    onClick={this.handleSubmit}
                  >
                    Sign Up ?
                  </button>
                  <br />
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h1> Awesome!</h1>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currentUserProps: state.user.currentUserProps,
  authUser: state.session.authUser
});

// HeaderNav = onClickOutside(HeaderNav);

export default connect(mapStateToProps)(SignUp);
