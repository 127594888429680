import { createStore, applyMiddleware, compose } from "redux";
import promiseMiddleware from "redux-promise";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

// import * as Actions from "./actions/index";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(promiseMiddleware, thunk))
);

// Actions.verifyAuth();

// window.store = store;

export default store;
