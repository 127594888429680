import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
// import { getAllIssues } from "../../actions/issues";
import { getAllCompanies } from "../../actions/companyActions";
import { nextFundSignUp } from "../../actions/userActions";
import "./index.css";
// import Sustain10 from "../assets/Sustain_v2_10.jpg";
// import SustainInfinity from "../assets/sustain_infinity.png";
import SustainBeta from "../assets/sustain-beta-test-logo-1-2x.png";
import SustainCommunity from "../assets/sustain_community_logo_1@2x.png";
import SustainImpact from "../assets/sustain-impact-logo-2x.png";
import SustainPassive from "../assets/sustain_passive_logo_2x.png";
import LeonGraph from "../assets/Sustain_Performance_through_March.png";

//Put back focus rings for keyboard users
//https://hackernoon.com/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      communityFundExpand: false,
      impactFundExpand: false,
      roboFundExpand: false
    };
    this.handleSignUp = this.handleSignUp.bind(this);
  }
  componentDidMount() {
    // getAllIssues();
    getAllCompanies();
  }
  handleExpand = (e, fundExpand) => {
    e.preventDefault();
    this.setState({
      [`${fundExpand}`]: !this.state[`${fundExpand}`]
    });
  };
  handleSignUp(e, fund) {
    e.preventDefault();
    if (this.props.currentUserProps) {
      nextFundSignUp(
        this.props.currentUserProps.email,
        this.props.currentUserProps.uid,
        this.props.currentUserProps.username,
        fund
      );
    } else {
      return <Redirect to="/login" />;
    }
  }
  render() {
    if (
      this.props.currentUserProps === null ||
      this.props.currentUserProps.uid === null
    ) {
      return <Redirect to="/login" />;
    }

    return (
      <div className="card">
        <div className="card-content">
          {/* <div className="index-header-title">Index </div> */}
          <div className="card-content">
            <div className="card">
              <Link to="/sustain-ten-feed">
                <div className="card-content">
                  <div className="new-fund-header">
                    {" "}
                    <img src={SustainBeta} alt="Sustain infinity logo" />
                  </div>
                </div>
              </Link>
              <img
                src={LeonGraph}
                style={styles.leonGraphStyle}
                alt="Leon test performance chart"
              />
            </div>
            <br />
            <div className="card">
              <div className="card-content">
                <div className="new-fund-header">
                  <button
                    className="new-fund-header-title"
                    onClick={e => this.handleExpand(e, "communityFundExpand")}
                  >
                    <img src={SustainCommunity} alt="Sustain infinity logo" />{" "}
                  </button>
                </div>
              </div>
              <footer className="card-footer">
                {this.state.communityFundExpand ? (
                  <p className="expanded-footer">
                    <span className="expand-body-p">
                      This is the core Sustain Fund. With the Sustain Activist
                      fund, your votes and engagement power rebalancing and
                      create Sustain Prices. Here, the Sustain Community also
                      engages the companies we own in attempt to better their
                      practices.
                    </span>
                    <br />
                    <br />
                    Universe: Large cap equities
                    <br />
                    Launch Date: 2019
                    <br />
                    <br />
                    {this.props.currentUserProps &&
                    this.props.currentUserProps.communityFund === true ? (
                      <span>Signed up for Community launch alert.</span>
                    ) : (
                      <button
                        className="next-fund-sign-up-btn"
                        onClick={e => this.handleSignUp(e, "communityFund")}
                      >
                        Sign up for Community fund
                      </button>
                    )}
                  </p>
                ) : null}
              </footer>
            </div>
            <div className="card">
              <div className="card-content">
                <div className="new-fund-header">
                  <button
                    className="new-fund-header-title"
                    onClick={e => this.handleExpand(e, "roboFundExpand")}
                  >
                    <img src={SustainPassive} alt="Sustain infinity logo" />{" "}
                  </button>
                </div>
              </div>
              <footer className="card-footer">
                {this.state.roboFundExpand ? (
                  <p className="expanded-footer">
                    <span className="expand-body-p">
                      - The Sustain Passive Fund is for those who want to put
                      their activism on cruise control! Here, while maintaining
                      the same transparency as the core Sustain Fund, the
                      Sustain Community leverages AI to help rebalance based on
                      the social and environmental impact of company behavior
                    </span>
                    <br />
                    <br />
                    Universe: Large cap equities
                    <br />
                    Launch Date: 2019
                    <br />
                    <br />
                    {this.props.currentUserProps &&
                    this.props.currentUserProps.roboFund === true ? (
                      <span>Signed up for Robo launch alert.</span>
                    ) : (
                      <button
                        className="next-fund-sign-up-btn"
                        onClick={e => this.handleSignUp(e, "roboFund")}
                      >
                        {" "}
                        Sign up for Passive Fund
                      </button>
                    )}
                  </p>
                ) : null}
              </footer>
            </div>
            <div className="card">
              <div className="card-content">
                <div className="new-fund-header">
                  <button
                    className="new-fund-header-title"
                    onClick={e => this.handleExpand(e, "impactFundExpand")}
                  >
                    <img src={SustainImpact} alt="Sustain infinity logo" />
                  </button>
                </div>
              </div>
              <footer className="card-footer">
                {this.state.impactFundExpand ? (
                  <p className="expanded-footer">
                    <span className="expand-body-p">
                      - This fund is for those looking to invest in solutions to
                      maximize impact. While this fund holds more risk, it also
                      has higher potential returns alongside higher potential
                      social and environmental outcomes.
                    </span>
                    <br />
                    <br />
                    Universe: Small cap equities
                    <br />
                    Launch Date: 2019
                    <br />
                    <br />
                    {this.props.currentUserProps &&
                    this.props.currentUserProps.impactFund === true ? (
                      <span>Signed up for Impact launch alert.</span>
                    ) : (
                      <button
                        className="next-fund-sign-up-btn"
                        onClick={e => this.handleSignUp(e, "impactFund")}
                      >
                        {" "}
                        Sign up for Impact Fund
                      </button>
                    )}
                  </p>
                ) : null}
              </footer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  leonGraphStyle: {
    marginTop: "-10px",
    padding: `10px`
  }
};
const mapStateToProps = state => ({
  allIssues: state.issues.currentIssuesProps,
  allCompanies: state.companies.currentCompaniesProps,
  authUser: state.session.authUser,
  currentUserProps: state.user.currentUserProps
});

export default connect(mapStateToProps)(Index);
