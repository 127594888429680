import store from "../store";
import { firebase } from "../firebase/firebase";

export const SET_ALL_SYMBOLS_COMPANIES = "SET_ALL_SYMBOLS_COMPANIES";
export const setAllSymbolsCompanies = allCompanies => ({
  type: SET_ALL_SYMBOLS_COMPANIES,
  allCompanies
});

export const getAllSymbolsCompanies = () => {
  let finalObj = {};
  firebase
    .database()
    .ref("companies")
    .on("value", snapshot => {
      const companies = snapshot.val();
      for (const item in companies) {
        if (companies[item]["stock"]) {
          finalObj[companies[item]["stock"]] = companies[item];
        }
      }
    });
  store.dispatch(setAllSymbolsCompanies(finalObj));
};

export const SET_ALL_SYMBOLS_ISSUES = "SET_ALL_SYMBOLS_ISSUES";
export const setAllSymbolsIssues = allIssues => ({
  type: SET_ALL_SYMBOLS_ISSUES,
  allIssues
});

export const getAllSymbolsIssues = symbol => {
  firebase
    .database()
    .ref("issues")
    .once("value")
    .then(snapshot => {
      store.dispatch(setAllSymbolsIssues(Object.values(snapshot.val())));
    });
};

//   var ref = firebase.database().ref("dinosaurs");
// ref.orderByChild("height").equalTo(25).on("child_added", function(snapshot) {
//   console.log(snapshot.key);
// });
