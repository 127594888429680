import { auth, db } from "../firebase/index";
var mixpanel = require("mixpanel-browser");

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const setCurrentUser = user => ({
  type: SET_CURRENT_USER,
  user
});

export const registerUser = (
  username,
  email,
  password,
  firstName,
  lastName
) => {
  return auth
    .doCreateUserWithEmailAndPassword(email, password)
    .then(authUser => {
      db.doCreateUser(authUser.user.uid, email, username, firstName, lastName);
    })
    .then(() => {
      // console.log("The Snap  " + snap.val());
      // debugger;
      mixpanel.track("Register", {
        email: email
      });
    })
    .catch(error => {
      console.log("Error creating user " + error);
    });
  // console.log("Error creating a user " + error);
};

export const loginUser = (email, password) => {
  return auth.doSignInWithEmailAndPassword(email, password);
};

export function forgotPassword(email) {
  return auth
    .doPasswordReset(email)
    .then(() => {
      window.alert("Check your email for reset link.");
    })
    .catch(error => {
      console.log("error in password reset of " + error);
    });
}
