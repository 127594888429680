import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { auth } from "../firebase/index";
// import { slide as Menu } from "react-burger-menu";
import sustain_logo from "../components/assets/sustain_logo.png";
import onClickOutside from "react-onclickoutside";

class HeaderNav extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   visible: "not-active dropdown"
    // };
    // this.collapse = this.collapse.bind(this);
    // this.props.toggle() = this.props.toggle().bind(this);
    this.NavigationNonAuth = this.NavigationNonAuth.bind(this);
    this.NavigationAdminAuth = this.NavigationAdminAuth.bind(this);
    this.NavigationAuth = this.NavigationAuth.bind(this);
  }
  handleClickOutside = evt => {
    this.props.collapse();
  };
  //https://codepen.io/graubnla/pen/EgdgZm

  // toggleVisibility = () => {
  //   if (this.state.visible === "not-active dropdown") {
  //     this.setState({ visible: "is-active dropdown" });
  //   } else if (this.state.visible === "is-active dropdown") {
  //     this.setState({ visible: "not-active dropdown" });
  //   }
  // };

  // collapse() {
  //   this.setState({ visible: "not-active dropdown" });
  // }
  NavigationAdminAuth = () => {
    return (
      <div className={this.props.visible} id="dropdown-nav-menu">
        <button
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={() => this.props.toggle()}
        >
          <span className="icon is-small">
            <i className="fas fa-bars" aria-hidden="true" />
          </span>
        </button>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <a
              className="dropdown-item"
              href="https://www.sustaininvesting.com"
              // target="_blank"
            >
              About
            </a>
            <Link
              to="/index"
              className="dropdown-item"
              onClick={() => this.props.toggle()}
            >
              Index
            </Link>

            <Link
              to="/pivotal-issues"
              onClick={() => this.props.toggle()}
              className="dropdown-item"
            >
              Edit Issues
            </Link>
            <Link
              to="/addIssue"
              onClick={() => this.props.toggle()}
              className="dropdown-item"
            >
              New Pivotal Issue
            </Link>

            <Link
              to="/addCompany"
              onClick={() => this.props.toggle()}
              className="dropdown-item"
            >
              New Company
            </Link>

            <hr className="dropdown-divider" />
            <button
              className="button border-none dropdown-item"
              onClick={auth.doSignOut}
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
    );
  };

  NavigationAuth = () => {
    return (
      <div className={this.props.visible} id="dropdown-nav-menu">
        <button
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={() => this.props.toggle()}
        >
          <span className="icon is-small">
            <i className="fas fa-bars" aria-hidden="true" />
          </span>
        </button>

        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <a
              className="dropdown-item"
              href="https://wwww.sustaininvesting.com"
              // target="_blank"
            >
              About
            </a>
            <Link
              onClick={() => this.props.toggle()}
              className="dropdown-item"
              to="/index"
            >
              Index
            </Link>

            <hr className="dropdown-divider" />

            <button
              className="button dropdown-item border-none"
              onClick={auth.doSignOut}
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
    );
  };

  NavigationNonAuth = () => {
    return (
      <div className={this.props.visible} id="dropdown-nav-menu">
        <button
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={() => this.props.toggle()}
        >
          <span className="icon is-small">
            <i className="fas fa-bars" aria-hidden="true" />
          </span>
        </button>

        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <a
              className="dropdown-item"
              href="https://www.sustaininvesting.com"
              // target="_blank"
            >
              About
            </a>
            <hr className="dropdown-divider" />
            <Link
              onClick={() => this.props.toggle()}
              to="/login"
              className="dropdown-item"
            >
              Log In / Register
            </Link>
          </div>
        </div>
      </div>
    );
  };
  render() {
    let navAuth;
    if (this.props.currentUserProps === null) {
      navAuth = this.NavigationNonAuth();
    } else if (
      this.props.currentUserProps &&
      this.props.currentUserProps.userType === "admin"
    ) {
      navAuth = this.NavigationAdminAuth();
    } else if (this.props.authUser) {
      navAuth = this.NavigationAuth();
    } else {
      navAuth = this.NavigationNonAuth();
    }
    return (
      <Fragment>
        <div className="header-nav-div">{navAuth}</div>
        <br />
        <div className="header-div">
          <Link to="/">
            <img
              className="header-icon"
              src={sustain_logo}
              alt={`Sustain's Header`}
            />
          </Link>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  allIssues: state.issues.currentIssuesProps,
  allCompanies: state.companies.currentCompaniesProps,
  currentUserProps: state.user.currentUserProps,
  authUser: state.session.authUser
});

// HeaderNav = onClickOutside(HeaderNav);

export default connect(mapStateToProps)(onClickOutside(HeaderNav));
