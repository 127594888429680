import { combineReducers } from "redux";
import issuesReducer from "./issuesReducer";
import companyReducer from "./companyReducer";
import sessionReducer from "./sessionReducer";
import stockReducer from "./stocksReducer";
import symbolsReducer from "./symbolsReducer";
import userReducer from "./userReducer";

//Import reducers here and then use combine reducer to export them

const rootReducer = combineReducers({
  issues: issuesReducer,
  companies: companyReducer,
  session: sessionReducer,
  stocks: stockReducer,
  symbols: symbolsReducer,
  user: userReducer
});

export default rootReducer;
