const initialState = {
  currentUserProps: { uid: null },
  allUsers: {},
  profileData: {}
};

// The user profile reducer to store the user profile state
function userReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_CURRENT_USER_PROPS": {
      return Object.assign({}, state, {
        currentUserProps: action.user
      });
    }
    case "UPDATE_USER_REDUX_DATA": {
      return Object.assign({}, state, {
        currentUserProps: action.newObj,
        profileData: action.newObj
      });
    }
    case "REMOVE_CURRENT_USER_PROPS": {
      return Object.assign({}, state, {
        currentUserProps: { uid: null }
      });
    }
    case "UPDATE_PROFILE_PHOTO_ACTION": {
      return Object.assign({}, state, {
        currentUserProps: {
          ...state.currentUserProps,
          profilePhoto: action.url
        }
      });
    }
    case "UPDATE_USER_LOCATION": {
      return Object.assign({}, state, {
        currentUserProps: {
          ...state.currentUserProps,
          location: action.place
        }
      });
    }
    case "SET_ALL_USER_PROPS": {
      return Object.assign({}, state, {
        allUsers: action.AllUsers
      });
    }
    case "SET_PROFILE_DATA": {
      return {
        ...state,
        isLoading: false,
        profileData: action.payload
      };
    }

    default:
      return state;
  }
}

export default userReducer;
