import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
// import { getAllIssues } from "../../actions/issues";
import { getAllCompanies } from "../../actions/companyActions";
import Sustain10 from "../assets/Sustain_v2_10.jpg";

class IndexMembers extends Component {
  componentDidMount() {
    // getAllIssues();
    getAllCompanies();
  }
  render() {
    if (this.props.authUser === null) {
      return <Redirect to="/login" />;
    }
    let companies = [];
    if (this.props.sustainTenProps) {
      companies = Object.values(this.props.sustainTenProps);
    }
    return (
      <div className="card">
        <div className="card-content">
          <div className="index-header-title">The Sustain 10</div>
          <br />
          <br />
          <div className="index-sustain-ten-list">
            {companies.map((company, index) => (
              <Link to={`/company/${companies[index].companyUid}`} key={index}>
                <div className="card-content sustain-ten-hover-div">
                  <header>{companies[index].name}</header>
                  <div className="field">{companies[index].stock}</div>

                  <div className="field">
                    <img
                      src={Sustain10}
                      width="75px"
                      alt="the sustain 10 logo"
                    />
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  allIssues: state.issues.currentIssuesProps,
  sustainTenProps: state.companies.sustainTenProps,
  sustainTenPrices: state.stocks.sustainTenStocks,
  authUser: state.session.authUser
});

export default connect(mapStateToProps)(IndexMembers);
