export const SustainTenArray = [
  "NEE",
  "KR",
  "BWA",
  "ETSY",
  "GOOGL",
  "TSLA",
  "XYL",
  "ECL",
  "MSFT",
  "PLD"
];
export const SustainTenObj = {
  Nee: "Nextera Energy",
  KR: "Kroger",
  BWA: "Borgwarner",
  ETSY: "Etsy",
  GOOGL: "Alphabet",
  TSLA: "Tesla",
  XYL: "Xylem",
  ECL: "Ecolab",
  MSFT: "Microsoft",
  PLD: "Prologis"
};
