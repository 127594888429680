import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxBallot } from "@fortawesome/pro-light-svg-icons";
import { setProfilToNull } from "../../actions/userActions";
import "./engage.css";

var FontAwesome = require("react-fontawesome");

class EngageUserCardType2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      visible: false,
      percent: true
    };
    // this.toggleVisibility = this.toggleVisibility.bind(this);
  }
  handleExpand = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  };
  renderLevel = () => {
    let voteArray = Object.values(this.props.user.vote);
    let userLevel;
    if (voteArray.length < 25) {
      userLevel = "Activist";
    } else if (voteArray.length < 75) {
      userLevel = "Sustainability Officer";
    } else if (voteArray.length < 150) {
      userLevel = "Freedom Fighter";
    } else if (voteArray.length < 250) {
      userLevel = "Impact Guru";
    } else if (voteArray.length >= 250) {
      userLevel = "Sustainability Ninja";
    }
    return userLevel;
  };
  renderTotal = () => {
    let voteArray = Object.values(this.props.user.vote);
    return voteArray.length;
  };

  toggleProfileProps = () => {
    this.props.actions.setProfilToNull();
  };
  togglePercentView = () => {
    this.setState({
      percent: !this.state.percent
    });
  };

  // toggleVisibility = () => this.setState({ visible: !this.state.visible });
  render() {
    return (
      <div className="engage-user-content-container">
        {/* <div> */}
        <button
          className="engage-user-name-button"
          onClick={e => this.handleExpand(e)}
        >
          <div className="engage-user-card-text-type2">
            <div className="user-photo-plus-name">
              {this.props.user.profilePhoto ? (
                <img
                  className="engage-list-image-type2"
                  src={this.props.user.profilePhoto}
                  alt={this.props.user.username}
                />
              ) : (
                <FontAwesome name="fal fa-user-circle" />
              )}
              <span style={{ fontWeight: "bold", letterSpacing: "0.1px" }}>
                {" "}
                {this.props.user.username}
              </span>
              , &nbsp;
              <span className="user-level-type2">
                {this.renderLevel(this.props.user.vote)}
              </span>
            </div>
            <div className="render-total-type2">
              <FontAwesomeIcon icon={faBoxBallot} size="1x" />
              &nbsp; &nbsp;
              {this.renderTotal()}
            </div>
          </div>
        </button>
        <br />
        {this.state.expanded ? (
          <React.Fragment>
            <br />
            {this.props.user.bio ? (
              <div className="engage-user-card-bio-2">
                I define sustainabilty as {this.props.user.bio}
              </div>
            ) : (
              ""
            )}
            <br />
            <Link
              to={`/users/${this.props.user.uid}`}
              onClick={this.toggleProfileProps()}
              className="user-type-2-engage-link"
            >
              Engage
            </Link>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setProfilToNull: function() {
        dispatch(setProfilToNull());
      }
    }
  };
}
const mapStateToProps = state => ({
  allIssues: state.issues.currentIssuesProps,
  allCompanies: state.companies.currentCompaniesProps,
  currentUserProps: state.user.currentUserProps,
  authUser: state.session.authUser
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EngageUserCardType2);
