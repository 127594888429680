import { auth } from "./firebase";

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password).catch(error => {
    // debugger;
    // console.log(error + error.message);
    if (error.code === "auth/email-already-in-use") {
      window.alert("Your email is already registered please log in.");
    }
    // else if (error.code === "auth/wrong-password") {
    //   window.alert(
    //     "Password must be at least 6 characters in length"
    //   );
    // // }
    else {
      window.alert("Sorry an error occured ->  " + error.message);
    }
  });

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
  auth.signInWithEmailAndPassword(email, password).catch(error => {
    if (error.code === "auth/user-not-found") {
      window.alert(
        "This email has not been registered yet. \n \n Scroll down and click Sign Up to get started"
      );
    } else if (error.code === "auth/wrong-password") {
      window.alert(
        "Sorry password is incorrect. Please retype or \n can reset with Forgot Password below"
      );
    } else {
      window.alert("Sorry an error " + error.message);
    }
  });

// Sign out
export const doSignOut = () => {
  auth.signOut();
};

// Password Reset
export const doPasswordReset = email => auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = password =>
  auth.currentUser.updatePassword(password);
