import { firebase } from "../firebase/firebase";
import uuidv4 from "uuid/v4";
import store from "../store";
var mixpanel = require("mixpanel-browser");

export function requestData() {
  return { type: "REQUEST_DATA" };
}
export function receivedData(payload) {
  return { type: "SET_PROFILE_DATA", payload: payload };
}

// Action Creator to set the user profile data to a payload of `user`
export const SET_CURRENT_USER_PROPS = "SET_CURRENT_USER_PROPS";
export const setCurrentUserProps = user => ({
  type: SET_CURRENT_USER_PROPS,
  user
});

// Action Creator to remove the user profile data from the current state - called in Authentication.js
export const REMOVE_CURRENT_USER_PROPS = "REMOVE_CURRENT_USER_PROPS";
export const removeCurrentUserProps = () => ({
  type: REMOVE_CURRENT_USER_PROPS
});

// Action Creator to set the user profile photo to new image
export const UPDATE_PROFILE_PHOTO_ACTION = "UPDATE_PROFILE_PHOTO_ACTION";
export const updateProfilePhotoAction = url => ({
  type: UPDATE_PROFILE_PHOTO_ACTION,
  url
});

export const UPDATE_USER_LOCATION = "UPDATE_USER_LOCATION";
export const setCurrentUserLocation = place => ({
  type: UPDATE_USER_LOCATION,
  place
});

export const SET_ALL_USER_PROPS = "SET_ALL_USER_PROPS";
export const setAllUserProps = AllUsers => ({
  type: SET_ALL_USER_PROPS,
  AllUsers
});

export const UPDATE_USER_REDUX_DATA = "UPDATE_USER_REDUX_DATA";
export const updateReduxUserData = newObj =>
  store.dispatch({
    type: UPDATE_USER_REDUX_DATA,
    newObj
  });

export const UPDATE_RDX_COMMENT = "UPDATE_RDX_COMMENT";
export const updateRdxComment = (index, newComment) => ({
  type: UPDATE_RDX_COMMENT,
  index,
  newComment
});

export const getAllUsers = () => {
  firebase
    .database()
    .ref(`users/`)
    .on("value", snap => {
      const AllUsers = snap.val();
      let finalObj = {};
      for (let user in AllUsers) {
        const theUser = AllUsers[user];
        //Extract the email from the user object to hide it
        const { email, ...restOfUser } = theUser;
        finalObj[user] = restOfUser;
      }
      store.dispatch(setAllUserProps(finalObj));
    });
};

// Writing the user profile data to the Firebase Realtime database
export const updateUserData = (userId, formObject) => {
  firebase
    .database()
    .ref(`/users/${userId}`)
    .update({
      bio: formObject.bio,
      company: formObject.company,
      jobRole: formObject.jobRole,
      location: formObject.location,
      name: formObject.name,
      occupation: formObject.occupation,
      profilePhoto: formObject.profilePhoto
    })
    .then(loadProfile(userId))
    .catch(function(error) {
      console.log("There was error updating the user " + error.code);
    });
};
//Updating the user on registration popup
export const updateUserRegistrationData = (userId, formObject) => {
  firebase
    .database()
    .ref(`/users/${userId}`)
    .update({
      bio: formObject.bio,
      occupation: formObject.occupation,
      profilePhoto: formObject.profilePhoto,
      status: "updatedUser"
    })
    .then(loadProfile(userId))
    .catch(function(error) {
      console.log("There was error updating the user " + error.code);
    });
};

// Loading the user profile from the Firebase Realtime database
export const loadProfile = userId => {
  firebase
    .database()
    .ref(`users/${userId}`)
    .once("value")
    .then(function(snap) {
      const currentUser = snap.val();
      mixpanel.identify(currentUser.uid);
      store.dispatch(setCurrentUserProps(currentUser));
      mixpanel.people.set({
        $email: currentUser.email,
        $distinct_id: currentUser.uid,
        $name: currentUser.username,
        uid: currentUser.uid,
        username: currentUser.username
      });
    });
};

export const vote = (userId, issueId, vote) => {
  firebase
    .database()
    .ref(`/users/${userId}/vote/${issueId}`)
    .update({
      timeAdded: Date.now(),
      vote
    });
};

export const UpdateUserLocation = (userId, place) => {
  const newLocation = place;
  firebase
    .database()
    .ref(`/users/${userId}`)
    .update({
      location: place
    })
    // .then(newLocation => {

    // })
    .catch(function(error) {
      console.log("There was error updating the user location " + error.code);
    });
  // loadProfile(userId);
  store.dispatch(setCurrentUserLocation(newLocation));
};

export function getProfileFromStore(data, pathName) {
  return function(dispatch) {
    dispatch(requestData());
    let pathArray = pathName.split("/");
    pathArray.splice(0, 1);
    let profileName = pathArray[1];
    let profileData = data[profileName];
    if (profileData) {
      dispatch(receivedData(profileData));
    } else {
      console.log(
        "Error in get profileFromStore within profileactioncreator.js "
      );
    }
  };
}

export function setProfilToNull() {
  return function(dispatch) {
    dispatch(requestData());
    let profileData = {};

    dispatch(receivedData(profileData));
  };
}

export function nextFundSignUp(email, uid, username, fund) {
  firebase
    .database()
    .ref(`/users/${uid}`)
    .update({
      [`${fund}`]: true
    })
    .then(
      firebase
        .database()
        .ref(`futureFunds/${fund}/${uid}`)
        .update({
          email,
          uid,
          username,
          timeRequested: Date.now()
        })
    )
    .then(loadProfile(uid))
    .catch(function(error) {
      console.log(
        "There was error updating nextFund on the user " + error.code
      );
    });
}

// addComment(this.state.comment, IssueId, AuthorId, DisplayName);
export const addComment = (
  comment,
  issueId,
  AuthorId,
  DisplayName,
  CurrentUserVoteStatus
) => {
  if (comment === "" || comment === undefined) {
    console.log("Nothing to comment");
    return;
  }
  //first update the public issue
  let issueCommentKey = firebase
    .database()
    .ref(`/issues/${issueId}/comments`)
    .push().key;
  firebase
    .database()
    .ref(`/issues/${issueId}/comments/${issueCommentKey}`)
    .update({
      key: issueCommentKey,
      text: comment,
      timeAdded: Date.now(),
      commentUser: AuthorId,
      DisplayName,
      commentUserVoteStatus: CurrentUserVoteStatus
    });

  //then add the comment to the users own vote history

  let userOwnCommentKey = firebase
    .database()
    .ref(`/users/${AuthorId}/vote/${issueId}/comments`)
    .push().key;
  firebase
    .database()
    .ref(`/users/${AuthorId}/vote/${issueId}/comments/${userOwnCommentKey}`)
    .update({
      key: userOwnCommentKey,
      text: comment,
      timeAdded: Date.now(),
      commentUser: AuthorId,
      commentUserVoteStatus: CurrentUserVoteStatus,
      DisplayName
    });

  // firebase
  //   .database()
  //   .ref(`users/${AuthorId}`)
  //   .once("value")
  //   .then(function(snap) {
  //     const currentUser = snap.val();
  //     store.dispatch(receivedData(currentUser));
  //   });
};

// this.props.allIssues, this.state.comment, IssueId, DisplayName;

export function addReduxComment(
  allIssues,
  comment,
  IssueId,
  DisplayName,
  CurrentUserVoteStatus
) {
  if (comment === "" || comment === undefined) {
    console.log("Nothing to redux comment");
    return;
  }
  const index = allIssues.findIndex(item => item.issueUid === IssueId);
  var newId = uuidv4();
  var newComment = {
    [newId]: {
      DisplayName: DisplayName,
      key: newId,
      text: comment,
      commentUserVoteStatus: CurrentUserVoteStatus
    }
  };
  store.dispatch(updateRdxComment(index, newComment));
}
