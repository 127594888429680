const initialState = {
  currentIssuesProps: []
  // allIssuesObject: {}
};

export default function insightReducer(state = initialState, action) {
  switch (action.type) {
    case "LOADING_ISSUES": {
      return {
        ...state,
        loadingIssues: true
      };
    }
    case "SET_CURRENT_ALL_ISSUES": {
      return {
        ...state,
        currentIssuesProps: [...action.allIssues],
        loadingIssues: false
      };
    }
    case "SET_NEXT_ISSUE_PROPS": {
      // let newArray = action.moreIssues.filter(
      //   item => state.currentIssuesProps.includes(item) === false
      // );
      return {
        ...state,
        currentIssuesProps: [...state.currentIssuesProps, ...action.moreIssues],
        loadingIssues: false
      };
    }
    case "UPDATE_RDX_COMMENT": {
      const updatedComments = state.currentIssuesProps.map((item, index) => {
        if (index === action.index) {
          item.comments = Object.assign({}, item.comments, action.newComment);
          return Object.assign({}, item);
        }
        return item;
      });
      return {
        ...state,
        currentIssuesProps: updatedComments
      };
    }
    // case "SET_ALL_ISSUES_OBJECT": {
    //   return Object.assign({}, state, {
    //     allIssuesObject: action.allIssues
    //   });
    // }
    default:
      return state;
  }
}
