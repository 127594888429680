import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
// import { getAllIssues } from "../../actions/issues";
import { getAllCompanies } from "../../actions/companyActions";

class IndexMembers extends Component {
  componentDidMount() {
    // getAllIssues();
    getAllCompanies();
  }
  render() {
    // if (this.props.authUser === null) {
    //   return <Redirect to="/login" />;
    // }
    let companies = [];
    if (this.props.allCompanies) {
      companies = this.props.allCompanies;
      // companies = Object.values(this.props.allCompanies);
    }

    /* console.log(JSON.stringify(this.props.allCompanies));
    let companies = Object.values(this.props.allCompanies);
    console.log(companies);
    let finalCompanies = Object.values(companies);
    let obj = {};
    let finalComs = Array.from(this.props.allCompanies).map(function(k) {
      return obj[k];
    });
  let finalComz = Object.keys(finalCompanies);
     console.log(finalCompanies);
    console.log(finalComs);
 console.log(finalComz);


let arrayOfObjects = Object.keys(
      JSON.stringify(this.props.allCompanies)
    ).map(key => {
      let ar = this.props.allCompanies[key];


      return ar;
    });
    console.log(this.props.allCompanies);
    console.log(arrayOfObjects);
   */

    return (
      <div className="card" style={styles.profileComponentStyle}>
        <div className="card-content" style={styles.cardContentStyle}>
          <div className="index-header-title">Index Members</div>
          <div className="index-members-list">
            {companies.map((company, index) => (
              <div
                className="card-content"
                style={styles.segmentStyle}
                key={index}
              >
                <div className="card-content" style={styles.cardContentStyle}>
                  <div className="card-content" style={styles.cardContentStyle}>
                    <header className="card-header">
                      <Link to={`/company/${companies[index].companyUid}`}>
                        {companies[index].name}
                      </Link>
                    </header>
                    <div className="field">{companies[index].stock}</div>
                    <div className="field">
                      <TimeAgo
                        date={companies[index].timeAdded}
                        style={{ textAlign: "right" }}
                      />
                      <div
                        style={{
                          justifyContent: "space-around",
                          // backgroundColor: "blue",
                          height: 35
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  profileComponentStyle: {
    padding: 0,
    marginBottom: 10,
    borderWidth: 10,
    boxShadow: "none",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
    // backgroundImage: `url(${Background})`
  },
  unorderedListStyle: {
    listStyle: "none"
  },
  cardHeaderStyle: {
    // backgroundColor: "#003533",
    backgroundColor: "#404040",
    color: "white",
    padding: 3,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingLeft: 25,
    margin: 0
  },
  cardContentStyle: {
    padding: 0,
    margin: 0
  },
  segmentStyle: {
    // backgroundImage: `url(${Background})`,
    borderWidth: 0
  },
  divStyle: {
    height: 7,
    backgroundImage: "linear-gradient(to right, #FFDF00, #F63800, #6400B5)"
  }
};
const mapStateToProps = state => ({
  allIssues: state.issues.currentIssuesProps,
  allCompanies: state.companies.currentCompaniesProps,
  authUser: state.session.authUser
});

export default connect(mapStateToProps)(IndexMembers);
