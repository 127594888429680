import store from "../store";
import { firebase } from "../firebase/firebase";
import { SustainTenArray } from "../components/Constants/index";

export const snapshotToArray = snapshot => {
  let returnArr = [];

  snapshot.forEach(childSnapshot => {
    let item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });

  return returnArr;
};

export const SET_CURRENT_ALL_COMPANIES = "SET_CURRENT_ALL_COMPANIES";
export const setCurrentAllCompaniesProps = allCompanies => ({
  type: SET_CURRENT_ALL_COMPANIES,
  allCompanies
});

export const SET_ALL_COMPANIES_OBJECT = "SET_ALL_COMPANIES_OBJECT";
export const setCurrentAllCompaniesObj = allCompanies => ({
  type: SET_ALL_COMPANIES_OBJECT,
  allCompanies
});

const SET_SUSTAIN_TEN_COMPANIES = "SET_SUSTAIN_TEN_COMPANIES";
export const setSustainTenStocks = tenCompanies => ({
  type: SET_SUSTAIN_TEN_COMPANIES,
  tenCompanies
});

const SET_SUSTAIN_TEN_ARRAY = "SET_SUSTAIN_TEN_ARRAY";
export const setSustainTenArray = tenArray => ({
  type: SET_SUSTAIN_TEN_ARRAY,
  tenArray
});

export const getAllCompanies = () => {
  firebase
    .database()
    .ref("companies")
    // .orderByChild("time")
    .on("value", function(snapshot) {
      const companies = snapshotToArray(snapshot);
      store.dispatch(
        setCurrentAllCompaniesProps(Array.from(companies.reverse()))
      );
      store.dispatch(setCurrentAllCompaniesObj(snapshot.val()));
      theSustainTenCompanies(snapshot.val(), SustainTenArray);
    });
};

function theSustainTenCompanies(companies, SustainTenArray) {
  if (!companies || !SustainTenArray) {
    return;
  }
  let sustainTenObject = {};
  let sustainTenArray = [];
  for (let item of SustainTenArray) {
    let finalCompany = Object.values(companies).filter(h => h.stock === item);
    sustainTenObject[`${item}`] = finalCompany[0];
    sustainTenArray.push(finalCompany[0]["companyUid"]);
  }

  store.dispatch(setSustainTenStocks(sustainTenObject));
  store.dispatch(setSustainTenArray(sustainTenArray));
}

export const addCompany = (name, stockSymbol) => {
  let companyRef = firebase.database().ref("companies");
  let newCompanyRef = companyRef.push();
  let Key = newCompanyRef.key;
  newCompanyRef.set({
    name: name,
    stock: stockSymbol,
    timeAdded: Date.now(),
    companyUid: Key
  });
  window.alert(`Your Company has been successfully Added`);
};
