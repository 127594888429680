import React from "react";
import VoteBar from "./VoteBar";

const VoteResults = ({ plus, minus }) => {
  if (minus === undefined) {
    minus = 0;
  }
  if (plus === undefined) {
    plus = 0;
  }
  let total = plus - minus;
  let plusHeight = 0;
  let minusHeight = 0;
  if (plus === 0) {
    plusHeight = 0;
  } else {
    plusHeight = plus / total;
  }

  if (minus === 0) {
    minusHeight = 0;
  } else {
    minusHeight = -minus / total;
  }

  return (
    <div>
      <h2 style={styles.h2Styles}> Community Votes</h2>
      <div style={styles.resultsContainer}>
        <div />
        <VoteBar height={minusHeight} sustain="false" />
        <div />
        <VoteBar height={plusHeight} sustain="true" />
        <div />
      </div>

      <div style={styles.percentageContainer}>
        <div />
        <h2>{Math.trunc(minusHeight * 100)}% </h2>
        <div />
        <h2>{Math.trunc(plusHeight * 100)}%</h2>
        <div />
      </div>
    </div>
  );
};

const styles = {
  h2Styles: {
    textAlign: "center"
  },
  percentageContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    padding: 0,
    margin: 10,
    height: "20px",
    paddingTop: 4,
    textAlign: "center",
    verticalAlign: "bottom"
    // backgroundImage: `url(${Background})`
  },
  resultsContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    padding: 0,
    margin: 10,
    borderBottom: "1px solid black",
    // borderRadius: 10,
    height: "110px",
    paddingTop: 4,
    verticalAlign: "bottom"
    // backgroundImage: `url(${Background})`
  },
  divChild: {
    alignSelf: "end"
  }
};
export default VoteResults;
