import React, { Component } from "react";
import { connect } from "react-redux";
import uuidv4 from "uuid/v4";
// import { Link } from "react-router-dom";
import { storageRef } from "../../firebase/firebase";
import { updateReduxUserData, updateUserData } from "../../actions/userActions";

class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bio: this.props.currentUserProps.bio || "",
      company: this.props.currentUserProps.company || "",
      jobRole: this.props.currentUserProps.jobRole || "",
      location: this.props.currentUserProps.location || "",
      name: this.props.currentUserProps.name || "",
      occupation: this.props.currentUserProps.occupation || "",
      profilePhoto: this.props.currentUserProps.profilePhoto || ""
    };
    this.handleImageSelect = this.handleImageSelect.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  // handleImageSelect(e) {
  //   this.setState(
  //     {
  //       profilePhoto: e.target.files[0]
  //     },
  //     function() {
  //       console.log(this.state.profilePhoto.name + " Profile Photo");
  //     }
  //   );
  // }
  handleImageSelect(e) {
    let self = this;
    let storeKey = uuidv4();
    const file = e.target.files[0];
    const filename = e.target.files[0].name;
    const profileTypeStorageRef = storageRef.child("profilePhotos/");
    const profileNameStorageRef = profileTypeStorageRef.child(storeKey);
    const fileURL = profileNameStorageRef.child(filename);
    // var uploadTask = fileURL.put(file);
    // uploadTask.on("state_changed", function(snapshot) {

    fileURL.put(file).then(function(snapshot) {
      snapshot.ref.getDownloadURL().then(function(downloadURL) {
        self.setState({ profilePhoto: downloadURL });
      });
    });
  }

  handleSubmit(event) {
    // let updatedName =
    //   this.state.name === this.props.currentUserProps.displayName || ""
    //     ? this.props.currentUserProps.name
    //     : this.state.name;

    // let updatedCompany =
    //   this.state.company === this.props.currentUserProps.company || ""
    //     ? this.props.currentUserProps.company
    //     : this.state.company;

    // let updatedJobRole =
    //   this.state.jobRole === this.props.currentUserProps.jobRole || ""
    //     ? this.props.currentUserProps.jobRole
    //     : this.state.jobRole;

    // let updatedBio =
    //   this.state.bio === this.props.currentUserProps.bio || ""
    //     ? this.props.currentUserProps.bio
    //     : this.state.bio;
    this.props.toggle();

    let formObject = {
      bio: this.state.bio,
      company: this.state.company,
      jobRole: this.state.jobRole,
      occupation: this.state.occupation,
      location: this.state.location,
      name: this.state.name,
      profilePhoto: this.state.profilePhoto
    };
    // this.setState({ [event.target.name]: event.target.value });
    updateUserData(this.props.authUser.uid, formObject);
    updateReduxUserData(formObject);
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div className="field">
            <label> Name</label>
            <input
              name="name"
              className="input"
              type="text"
              onChange={this.handleInputChange}
              placeholder={this.state.name}
            />
          </div>
          {/* <br />
          <div className="field">
            <label> Company</label>
            <input
              name="company"
              className="input"
              type="text"
              onChange={this.handleInputChange}
              placeholder={this.state.company}
            />
          </div> */}
          <br />
          <div className="field">
            <label> Occupation</label>
            <input
              name="occupation"
              className="input"
              type="text"
              onChange={this.handleInputChange}
              value={this.state.occupation}
            />
          </div>
          <br />
          <div className="field">
            <label> I Define Sustainability as</label>
            <input
              name="bio"
              className="input"
              type="text"
              onChange={this.handleInputChange}
              value={this.state.bio}
            />
          </div>
          <br />
          <div className="field">
            <label> Location</label>
            <input
              name="location"
              className="input"
              type="text"
              onChange={this.handleInputChange}
              value={this.state.location}
            />
          </div>
          {/* <div>
          <label> Profile Photo</label>
          <input
            name="profilePhoto"
            component="input"
            type="url"
            placeholder="Paste Url to photo location for now"
            value={this.state.profilePhoto}
            onChange={this.handleInputChange}
          />
        </div> */}
          <br />
          <div className="field">
            <label>Profile Photo</label>
            <input
              className="input"
              type="file"
              name="profilePhoto"
              id="profilePhoto"
              onChange={this.handleImageSelect}
            />
            {this.state.profilePhoto && (
              <img
                src={this.state.profilePhoto}
                width="250px"
                alt={`User Profile`}
              />
            )}
          </div>
          <br />
          {/* <Button type="submit">Submit</Button> */}
          <button
            className="profile-update-button"
            type="submit"
            disabled={this.props.pristine || this.props.submitting}
            onClick={this.handleSubmit}
          >
            Update
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUserProps: state.user.currentUserProps,
  authUser: state.session.authUser
  // authUser: state.session.authUser,
});

export default connect(mapStateToProps)(ProfileForm);
