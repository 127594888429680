import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAllIssues } from "../actions/issues";
import { getAllCompanies } from "../actions/companyActions";
// import IssueCard from "./IssueCard";
// import TimeAgo from "react-timeago";
// import { runInThisContext } from "vm";

class PivotalIssues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleMinus = this.handleMinus.bind(this);
    this.renderCompanyName = this.renderCompanyName.bind(this);
  }
  componentDidMount() {
    getAllCompanies();
    getAllIssues();
  }

  handleAdd() {
    let newCount = this.state.count + 1;
    this.setState({ count: newCount });
  }

  handleMinus() {
    let newMinus = this.state.count - 1;
    this.setState({ count: newMinus });
  }
  renderCompanyName(issue) {
    if (Object.keys(this.props.allCompanies).length > 2 && issue.companyKey) {
      return this.props.allCompanies[`${issue.companyKey}`].name;
    }
  }
  render() {
    return (
      <Fragment>
        <div className="card" style={styles.profileComponentStyle}>
          <div className="card-content" style={styles.cardContentStyle}>
            <div className="card-content" style={styles.segmentStyle}>
              {/* <Item.Group divided> */}
              <div className="card-content">
                <ul>
                  {this.props.allIssues ? (
                    // Object.values
                    this.props.allIssues.map((issue, index) => (
                      <li key={index} index={index} issue={issue}>
                        <Link to={`/edit-issue/${issue.issueUid}`}>Edit: </Link>
                        {"  "}
                        <span style={{ fontWeight: "bold" }}>
                          {this.renderCompanyName(issue)}
                        </span>
                        : {issue.title}
                      </li>
                    ))
                  ) : (
                    <div>
                      {" "}
                      <p> no issues loaded </p>{" "}
                    </div>
                  )}
                </ul>
              </div>
              {/* </Item.Group> */}
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
      </Fragment>
    );
  }
}

const styles = {
  profileComponentStyle: {
    padding: 0,
    marginBottom: 10,
    borderWidth: 10,
    boxShadow: "none",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
    // backgroundImage: `url(${Background})`
  },
  unorderedListStyle: {
    listStyle: "none"
  },
  cardHeaderStyle: {
    // backgroundColor: "#003533",
    backgroundColor: "#009933",
    color: "white",
    padding: 3,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingLeft: 25,
    margin: 0
  },
  cardContentStyle: {
    padding: 0,
    margin: 0
  },
  segmentStyle: {
    // backgroundImage: `url(${Background})`,
    borderWidth: 0
  },
  divStyle: {
    height: 7,
    backgroundImage: "#FFDF00"
  }
};

const mapStateToProps = state => ({
  allIssues: state.issues.currentIssuesProps,
  allCompanies: state.companies.allCompaniesObject
});

export default connect(mapStateToProps)(PivotalIssues);
