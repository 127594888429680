const initialState = {
  currentStockProps: {},
  sustainTenStocks: {}
};

export default function stockReducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_STOCKS": {
      return Object.assign({}, state, {
        currentStockProps: action.data
      });
    }
    case "ADD_SUSTAIN_TEN_STOCKS": {
      return Object.assign({}, state, {
        sustainTenStocks: action.data
      });
    }
    default:
      return state;
  }
}
