const initialState = {
  currentCompaniesProps: [],
  allCompaniesObject: {},
  sustainTenProps: {},
  sustainTenArray: []
};

export default function companyReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_CURRENT_ALL_COMPANIES": {
      return {
        ...state,
        currentCompaniesProps: [...action.allCompanies]
      };
    }
    case "SET_SUSTAIN_TEN_COMPANIES": {
      return Object.assign({}, state, {
        sustainTenProps: action.tenCompanies
      });
    }
    case "SET_ALL_COMPANIES_OBJECT": {
      return Object.assign({}, state, {
        allCompaniesObject: action.allCompanies
      });
    }
    case "SET_SUSTAIN_TEN_ARRAY": {
      return {
        ...state,
        sustainTenArray: [...action.tenArray]
      };
    }
    default:
      return state;
  }
}

/*

return Object.assign({}, state, {
  currentCompaniesProps: action.allCompanies
});

*/
