import store from "../store";
import { firebase } from "../firebase/firebase";

// notes on infinite scrolling

//https://hackernoon.com/infinite-scrolling-in-firebase-e28bfbc53578

export const snapshotToArray = snapshot => {
  let returnArr = [];

  snapshot.forEach(childSnapshot => {
    let item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });

  return returnArr;
};

export const SET_CURRENT_ALL_ISSUES = "SET_CURRENT_ALL_ISSUES";
export const setCurrentAllIssuesProps = allIssues => ({
  type: SET_CURRENT_ALL_ISSUES,
  allIssues
});

export const SET_NEXT_ISSUE_PROPS = "SET_NEXT_ISSUE_PROPS";
export const setNextIssuesProps = moreIssues => ({
  type: SET_NEXT_ISSUE_PROPS,
  moreIssues
});

export const LOADING_ISSUES = "LOADING_ISSUES";
export const loadingData = () => ({
  type: LOADING_ISSUES
});

export const getAllIssues = () => {
  let issuesArray = [];
  store.dispatch(loadingData());
  firebase
    .database()
    .ref("issues")
    .orderByKey()
    // .limitToLast(2)
    .once("value")
    .then(snapshot => {
      const issues = snapshot.val();
      issuesArray = Object.values(issues).reverse();
      store.dispatch(setCurrentAllIssuesProps(issuesArray));
    });
};

export const addIssue = (
  formObj
  // editorState
) => {
  formObj.timeAdded = Date.now();
  let issueRef = firebase.database().ref("issues");
  let newIssueRef = issueRef.push();
  let Key = newIssueRef.key;
  formObj.issueUid = Key;
  newIssueRef.update(formObj);
  window.alert(`Issue has been successfully uploaded...`);
};

// var ref = new Firebase('https://tutorialsfirebase.firebaseio.com');

// var amandaAgeRef = ref.child("players").child("-KGb1Ls-gEErWbAMMnZC").child('age');

// amandaAgeRef.transaction(function(currentAge) {
//    return currentAge + 1;
// });

export const plusOne = key => {
  let issueRef = firebase
    .database()
    .ref("issues")
    .child(key)
    .child("plusCount");
  issueRef.transaction(function(plusCount) {
    return plusCount + 1;
  });
};

export const minusOne = key => {
  let issueRef = firebase
    .database()
    .ref("issues")
    .child(key)
    .child("minusCount");
  issueRef.transaction(function(minusCount) {
    return minusCount - 1;
  });
};

export const editIssue = (
  issueID,
  title,
  summary,
  company,
  p,
  sentence,
  sourceLink,
  issuePhoto
  // editorState
) => {
  let issueRef = firebase.database().ref(`issues/${issueID}`);
  issueRef.update({
    title,
    summary,
    companyKey: company,
    pTag: p,
    overImageSentence: sentence,
    citation: sourceLink,
    timeUpdated: Date.now(),
    issuePhoto
  });
  window.alert(`Issue has been successfully updated..`);
};

export const updateIssueCategoryFields = issue => {
  debugger;
  const key = issue.issueUid;
  console.log(key);
  console.dir(issue);
  // debugger;
  issue.corporate = false;
  // issue.environmental = false;
  // issue.international = false;
  // issue.social = false;
  // issue.tech = false;

  let issueRef = firebase.database().ref("issues");
  issueRef.update({
    [key]: issue
  });
  console.log("Issue has been successfully updated.." + key);
};
