import React, { Component } from "react";
import { connect } from "react-redux";
// import { getStockPrice } from "../selectors";
// import { Link } from "react-router-dom";
// import TimeAgo from "react-timeago";
import IssueCard from "../IssueCard";
// import { checkStockPrices } from "../actions/stockActions";
// import FooterNav from "./FooterNav";
class CompanyProfile extends Component {
  render() {
    console.log("Just the one company " + this.props.match.params.companyId);
    let result = this.props.allIssues.filter(
      issue => issue["companyKey"] === this.props.match.params.companyId
    );
    let theCompany = [];
    if (this.props.allCompanies) {
      theCompany = this.props.allCompanies.filter(
        company => company["companyUid"] === this.props.match.params.companyId
      );
    }

    let finalCompany = [];
    if (theCompany.length > 0) {
      finalCompany = theCompany[0];
    }
    let theTicker;
    if (finalCompany) {
      theTicker = finalCompany.stock;
    }

    let stockPrice;
    if (this.props.allStocks[`${theTicker}`] !== undefined) {
      stockPrice = this.props.allStocks[`${theTicker}`].price;
    }

    return (
      <div className="card" style={styles.profileComponentStyle}>
        <div className="card-content" style={styles.cardContentStyle}>
          <div className="card-content">
            {finalCompany.name && (
              <header className="card-header">
                <h1 style={{ fontSize: "2em" }}>{finalCompany.name}</h1>
                <br />
                &nbsp; {finalCompany.stock} &nbsp;
                {stockPrice}
              </header>
            )}
          </div>
          <br />
          <br />

          <div />
          {result.map((issue, index) => (
            <IssueCard key={index} index={index} issue={issue} />
          ))}
        </div>
        {/* <FooterNav {...finalCompany} /> */}
      </div>
    );
  }
}

const styles = {
  profileComponentStyle: {
    padding: 0,
    marginBottom: 10,
    borderWidth: 10,
    boxShadow: "none",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
    // backgroundImage: `url(${Background})`
  },
  unorderedListStyle: {
    listStyle: "none"
  },
  cardHeaderStyle: {
    // backgroundColor: "#003533",
    backgroundColor: "#404040",
    color: "white",
    padding: 3,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingLeft: 25,
    margin: 0
  },
  cardContentStyle: {
    padding: 0,
    margin: 0
  },
  segmentStyle: {
    // backgroundImage: `url(${Background})`,
    borderWidth: 0
  },
  divStyle: {
    height: 7,
    backgroundImage: "linear-gradient(to right, #FFDF00, #F63800, #6400B5)"
  }
};
const mapStateToProps = state => ({
  allIssues: state.issues.currentIssuesProps,
  allCompanies: state.companies.currentCompaniesProps,
  allStocks: state.stocks.currentStockProps
});

// const mapStateToProps = state => ({

//  getStockPrice(theTicker, state)

// }

export default connect(mapStateToProps)(CompanyProfile);
