import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";

import { checkStockPrices } from "../actions/stockActions";

class Nick extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleStock = this.handleStock.bind(this);
  }

  handleStock() {
    checkStockPrices();
  }
  render() {
    // const { issue } = this.props;

    return (
      <Fragment>
        <div className="issue-card-button-div">
          <button onClick={this.handleStock}>Check Stock Prices</button>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  allIssues: state.issues.currentIssuesProps,
  allCompanies: state.companies.currentCompaniesProps
});

export default connect(mapStateToProps)(Nick);
