import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { firebase } from "../firebase/firebase";
import InfiniteScroll from "react-infinite-scroll-component";
import store from "../store";
import {
  // getAllIssues,
  setCurrentAllIssuesProps,
  setNextIssuesProps
} from "../actions/issues";
import { getAllCompanies } from "../actions/companyActions";
import IssueCard from "./IssueCard";
const Loading = require("react-loading-animation");

// import TimeAgo from "react-timeago";
// import { runInThisContext } from "vm";

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCount: 0,
      currentIssue: "",
      confettiIterator: 5,
      confettiState: false,
      confettiStatus: false,
      referenceToOldestKey: ""
    };
  }
  componentDidMount() {
    // this.clearTheIssue()
    getAllCompanies();
    this.getIssues();
  }

  componentWillUnmount() {
    // if (this.props.authUser && this.props.authUser.uid) {
    //   loadProfile(this.props.authUser.uid);
    // }
    // store.dispatch(setCurrentAllIssuesProps([]));
  }

  confettiRandom = () => {
    const numberArray = [3, 4, 5, 6, 7];
    const conNumber =
      numberArray[Math.floor(Math.random() * numberArray.length)];
    return conNumber;
  };
  getIssues = () => {
    // let self = this;
    if (
      !this.state.referenceToOldestKey ||
      this.state.referenceToOldestKey === ""
    ) {
      // if initial fetch

      firebase
        .database()
        .ref("issues")
        .orderByKey()
        .limitToLast(25)
        .once("value")
        .then(snapshot => {
          // changing to reverse chronological order (latest first)
          let arrayOfKeys = Object.keys(snapshot.val())
            .sort()
            .reverse();
          // transforming to array
          let results = arrayOfKeys.map(key => snapshot.val()[key]);
          // storing reference
          let oldestKey = arrayOfKeys[arrayOfKeys.length - 1];
          // console.log("oldest key " + oldestKey);
          this.setState({ referenceToOldestKey: oldestKey }, () => {
            store.dispatch(setCurrentAllIssuesProps(results));
          });
        })
        .catch(error => {
          console.log("Error in getIssues " + error);
        });
    } else {
      firebase
        .database()
        .ref("issues")
        .orderByKey()
        .endAt(this.state.referenceToOldestKey)
        .limitToLast(25)
        .once("value")
        .then(snapshot => {
          // changing to reverse chronological order (latest first)
          // & removing duplicate
          let arrayOfKeys = Object.keys(snapshot.val())
            .sort()
            .reverse()
            .slice(1);
          // transforming to array
          let results = arrayOfKeys.map(key => snapshot.val()[key]);
          // updating reference
          let oldestKey = arrayOfKeys[arrayOfKeys.length - 1];
          // Do what you want to do with the data, i.e.
          this.setState({ referenceToOldestKey: oldestKey }, () => {
            store.dispatch(setNextIssuesProps(results));
          });

          // append to page or dispatch({ … }) if using redux
        })
        .catch(error => {
          console.log("Error in 2nd part getIssues " + error);
        });
    }
  };

  increaseCount = issueId => {
    let newCount = this.state.currentCount + 1;
    this.setState({
      currentCount: newCount,
      confettiState: true,
      currentIssue: issueId
    });
  };
  renderChildren = () => {
    let issuesArray = [];
    let finalArray = [];
    let voteArray = [];
    if (this.props.currentUserProps && this.props.currentUserProps.vote) {
      voteArray = Object.keys(this.props.currentUserProps.vote);
    }

    // console.log(voteArray);

    issuesArray = Object.values(this.props.allIssues);
    // finalArray = issuesArray;

    //Filter out the already voted on issues
    finalArray = issuesArray.filter(item => !voteArray.includes(item.issueUid));
    // currentUserProps.vote
    return (
      <InfiniteScroll
        dataLength={finalArray.length}
        next={this.getIssues}
        hasMore={true}
        loader={<Loading />}
      >
        {this.props.allIssues && this.props.allCompanies
          ? finalArray.map((issue, index) => (
              <IssueCard
                key={index}
                index={index}
                issue={issue}
                increase={this.increaseCount}
                confetti={this.state.confettiStatus}
                currentIssueId={this.state.currentIssue}
              />
            ))
          : null}
      </InfiniteScroll>
    );
  };
  render() {
    if (this.props.authUser === null) {
      return <Redirect to="/register" />;
    }
    const { confettiIterator, currentCount, confettiState } = this.state;
    if (
      currentCount > 3 &&
      currentCount % confettiIterator === 0 &&
      confettiState === true
    ) {
      let newIterator = this.confettiRandom();
      this.setState({
        confettiInterator: newIterator,
        confettiStatus: true,
        confettiState: false
      });
    }
    if (this.state.confettiStatus === true) {
      setTimeout(this.setState({ confettiStatus: false }), 5000);
    }

    return (
      <Fragment>
        <div className="card" style={styles.profileComponentStyle}>
          <div className="card-content" style={styles.cardContentStyle}>
            {this.renderChildren()}
          </div>
        </div>
      </Fragment>
    );
  }
}

const styles = {
  profileComponentStyle: {
    padding: 0,
    marginBottom: 10,
    borderWidth: 10,
    boxShadow: "none",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
    // backgroundImage: `url(${Background})`
  },
  unorderedListStyle: {
    listStyle: "none"
  },
  cardHeaderStyle: {
    // backgroundColor: "#003533",
    backgroundColor: "#009933",
    color: "white",
    padding: 3,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingLeft: 25,
    margin: 0
  },
  cardContentStyle: {
    padding: 0,
    margin: 0
  },
  segmentStyle: {
    // backgroundImage: `url(${Background})`,
    borderWidth: 0
  },
  divStyle: {
    height: 7,
    backgroundImage: "#FFDF00"
  }
};

const mapStateToProps = state => ({
  allIssues: state.issues.currentIssuesProps,
  allCompanies: state.companies.currentCompaniesProps,
  authUser: state.session.authUser,
  currentUserProps: state.user.currentUserProps,
  loading: state.issues.loadingIssues
});

export default connect(mapStateToProps)(LandingPage);
