import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import uuidv4 from "uuid/v4";
import { registerUser } from "../actions/authActions";
import { updateUserRegistrationData } from "../actions/userActions";
import { storageRef } from "../firebase/firebase";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstPage: true,
      firstName: "",
      lastName: "",
      modal: false,
      password: "",
      profilePhoto: "",
      occupation: "",
      sustainDef: "",
      username: "",
      showActivist: false
    };
    this.handleImageSelect = this.handleImageSelect.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      modal: false
    });
  }
  handleImageSelect(e) {
    let self = this;
    let storeKey = uuidv4();
    const file = e.target.files[0];
    const filename = e.target.files[0].name;
    const profileTypeStorageRef = storageRef.child("profilePhotos/");
    const profileNameStorageRef = profileTypeStorageRef.child(storeKey);
    const fileURL = profileNameStorageRef.child(filename);
    // var uploadTask = fileURL.put(file);
    // uploadTask.on("state_changed", function(snapshot) {

    fileURL.put(file).then(function(snapshot) {
      snapshot.ref.getDownloadURL().then(function(downloadURL) {
        self.setState({ profilePhoto: downloadURL });
      });
    });
  }
  handleSubmit = e => {
    e.preventDefault();
    const requiredArray = [
      ["firstName", "your First Name"],
      ["lastName", "your Last Name"],
      ["username", " a Username"],
      ["email", " your email"],
      ["password", " a password"]
    ];

    for (let item of requiredArray) {
      if (this.state[item[0]] === "") {
        alert("Please include " + item[1]);
        return;
      }
    }

    registerUser(
      this.state.username,
      this.state.email,
      this.state.password,
      this.state.firstName,
      this.state.lastName
    );

    this.setState({ showActivist: true });
  };

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  nextPage = () => {
    this.setState({
      firstPage: false
    });
  };
  renderUserRegistration = user => {
    if (
      user.uid !== null &&
      user.status === "newUser" &&
      this.state.modal === false
    ) {
      this.setState({
        modal: "is-active"
      });
    }
  };

  closeModal = () => {
    this.setState({
      modal: false
    });
  };
  saveUserProgress = () => {
    // console.log(this.state.sustainDef);
    if (this.props.authUser.uid && this.state.sustainDef.trim().length > 1) {
      // console.log(this.state.sustainDef);
      let formObject = {
        bio: this.state.sustainDef,
        occupation: this.state.occupation,
        profilePhoto: this.state.profilePhoto
      };

      updateUserRegistrationData(this.props.authUser.uid, formObject);
    }
  };

  // registerPopUp = user => {
  //   if (
  //     user !== null &&
  //     user.status === "newUser" &&
  //     this.state.modal === false
  //   ) {
  //     this.setState({
  //       modal: "is-active"
  //     });
  //   }
  // };
  render() {
    if (
      this.props.currentUserProps &&
      this.props.currentUserProps.uid !== null &&
      this.props.currentUserProps.status !== "newUser"
    ) {
      return <Redirect to={`/`} />;
    }
    return (
      <Fragment>
        <div className="card" style={styles.profileComponentStyle}>
          <div className="card-content" style={styles.cardContentStyle}>
            <div style={styles.headerContainerStyle}>
              <h3>Please fill out the fields below to get started</h3>
            </div>
            <div style={styles.divStyle} />
            <div className="card-content" style={styles.segmentStyle}>
              <form
                id="theUploadForm"
                onSubmit={this.handleSubmit}
                style={styles.formStyle}
              >
                <div className="field">
                  <input
                    className="input"
                    placeholder="First Name"
                    name="firstName"
                    style={styles.inputStyle}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="field">
                  <input
                    className="input"
                    placeholder="Last Name"
                    name="lastName"
                    style={styles.inputStyle}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="field">
                  <input
                    className="input"
                    placeholder="Username"
                    name="username"
                    style={styles.inputStyle}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="field">
                  <input
                    className="input"
                    placeholder="E-mail"
                    name="email"
                    style={styles.inputStyle}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="field">
                  <input
                    className="input"
                    placeholder="Password"
                    type="password"
                    style={styles.inputStyle}
                    onChange={this.handleInputChange}
                    name="password"
                  />
                </div>

                {/* <Button fluid type="submit"> */}
                <button
                  className="button is-link "
                  style={styles.buttonSignUpStyle}
                  onClick={this.handleSubmit}
                >
                  Sign Up
                </button>
                <br />
                <Link
                  to="/login"
                  className="button is-info is-outlined"
                  style={styles.buttonStyle}
                >
                  Already a member? Login
                </Link>
              </form>
            </div>
          </div>
        </div>
        {/* {this.registerPopUp()} */}
        {this.renderUserRegistration(this.props.currentUserProps)}
        <div className="modal-container-div">
          <div className={`modal ${this.state.modal}`}>
            {/* <div className={`modal is-active`}> */}
            <div className="modal-background" />
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Welcome to Sustain</p>
                {/* <button
                  className="delete"
                  aria-label="close"
                  onClick={e => this.closeModal()}
                /> */}
              </header>
              {this.state.firstPage === true ? (
                <section className="modal-card-body">
                  We are excited to welcome you to this journey of responsible
                  ownership!
                  <br />
                  <br />
                  There are many different perceptions of Sustainability, please
                  share with the Sustain community your own definition,
                  occupation (or life-focus), and a picture (optional)
                  <br />
                  <br />
                  <label>How do you define Sustainability?</label>
                  <textarea
                    className="textarea  modal-card-input"
                    rows="3"
                    placeholder="Your Sustain Definition"
                    name="sustainDef"
                    onChange={this.handleInputChange}
                  />
                  <br />
                  <label>Occupation or focus:</label>
                  <br />
                  <input
                    className="input modal-card-input"
                    type="text"
                    placeholder="Occupation"
                    name="occupation"
                    onChange={this.handleInputChange}
                  />
                  <br />
                  <br />
                  <label>Profile Photo:</label>
                  <br />
                  <input
                    className="input modal-card-input"
                    type="file"
                    name="profilePhoto"
                    id="profilePhoto"
                    onChange={this.handleImageSelect}
                  />
                  <br />
                  {this.state.profilePhoto && (
                    <img
                      src={this.state.profilePhoto}
                      width="250px"
                      alt={`User Profile`}
                    />
                  )}
                  <br />
                  <br />
                  {this.state.sustainDef && this.state.sustainDef.length > 5 && (
                    <button
                      className="button is-success"
                      onClick={e => this.nextPage()}
                    >
                      Next
                    </button>
                  )}
                  <div className="register-spacing-div" />
                </section>
              ) : (
                <div>
                  <section className="modal-card-body">
                    You are ready to start voting!
                    <br />
                    <br />
                    Remember, voting is critical to fund rebalancing. The more
                    you engage with Sustain, the faster you'll move through the
                    Sustain levels. You will begin as an Activist. Happy voting.
                    <br />
                    <br />
                    Know what you own!
                  </section>
                  {/* <section className="modal-card-body">
                    Awesome, you are ready to start voting! {"  "} You will
                    begin as an Activist.
                    <br />
                    <br />
                    Remember, voting is critical to fund rebalancing, and
                    holding companies accountable. Once you have completed 25
                    votes, you will move on to becoming a Sustainability
                    Officer. The more you engage with Sustain, the faster you'll
                    move through the various levels and be to Sustainable Ninja
                    (the ultimate Sustain member). Once a Ninja, you will be
                    able to share, post and report on company behavior.
                    <br />
                    <br />
                    Know what you own!
              </section>*/}
                  <footer className="modal-card-foot">
                    <button
                      className="button is-success"
                      onClick={e => this.saveUserProgress()}
                    >
                      Start Voting
                    </button>

                    {/* <button className="button"></button> */}
                  </footer>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const styles = {
  profileComponentStyle: {
    margin: "0 auto 100px auto",
    border: "1px solid lightgray",
    padding: "20px",
    boxShadow: "5px 5px 5px grey",
    borderRadius: 5,
    width: "80%"
  },
  cardContentStyle: {
    padding: 0,
    margin: "0 auto"
  },
  headerContainerStyle: {
    width: "65%",
    margin: "0 auto",
    textAlign: "center"
  },
  segmentStyle: {
    boxShadow: "none",
    borderWidth: 0
    // backgroundImage: `url(${Background})`
  },
  divStyle: {
    height: 7,
    backgroundImage: "#FFDF00"
  },
  buttonSignUpStyle: {
    width: "100%",
    margin: "10px 10px 10px 0",
    fontSize: "1.10em",
    backgroundColor: "#004a8e"
  },
  buttonStyle: {
    width: "100%",
    margin: "10px 10px 10px 0",
    fontSize: ".90em",
    color: "#004a8e",
    borderColor: "#004a8e"
  },
  inputStyle: {
    borderTopStyle: "none",
    borderRight: 0,
    borderLeft: 0,
    margin: "10px 10px 10px 0",
    fontSize: "1.10em"
  }
};

// export default Register;

const mapStateToProps = state => ({
  authUser: state.session.authUser,
  currentUserProps: state.user.currentUserProps
});

export default connect(mapStateToProps)(Register);
