import React from "react";
import { Link } from "react-router-dom";
import Comments from "./Comments";
import VoteResults from "../Issues/VoteResults";
var FontAwesome = require("react-fontawesome");

// var mixpanel = require("mixpanel-browser");

const UserIssueCard = props => {
  // userVote={userVote}
  // companies={this.props.allCompanies}
  // allIssues={issues}
  let theCompany;
  let stockTicker;
  let companyName;
  const theVote = props.user.vote[`${props.userVote}`]["vote"];
  const theIssueArray = props.allIssues.filter(
    issue => issue.issueUid === props.userVote
  );
  const theIssue = theIssueArray[0];
  if (!theIssue) {
    return null;
  }
  if (props.companies && Object.keys(props.companies).length > 0) {
    theCompany = props.companies[`${theIssue.companyKey}`];
    companyName = theCompany["name"];
    stockTicker = theCompany["stock"];
  }

  let stockPrice;
  if (companyName && props.allStocks[`${stockTicker}`] !== undefined) {
    let stockPriceFirst = props.allStocks[`${stockTicker}`].lastSalePrice;
    stockPrice = stockPriceFirst.toFixed(2);
  }

  const styles = {
    profileComponentStyle: {
      padding: 0,
      marginBottom: 20,
      border: "1px solid lightgray",
      // borderWidth: 10,
      boxShadow: "none",
      borderRadius: 20
      // backgroundImage: `url(${Background})`
    },
    unorderedListStyle: {
      listStyle: "none"
    },
    cardHeaderStyle: {
      // backgroundColor: "#003533",
      backgroundColor: "#009933",
      color: "white",
      padding: 3,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      paddingLeft: 25,
      margin: 0
    },
    cardContentStyle: {
      padding: "10px",
      margin: 0
    },
    segmentStyle: {
      // backgroundImage: `url(${Background})`,
      borderWidth: 0
    },
    linkStyle: {
      textDecoration: "none",
      color: "black",
      cursor: "pointer"
    },
    linkWhiteStyle: {
      textDecoration: "none",
      color: "white",
      cursor: "pointer"
    },
    divStyle: {
      height: 7,
      backgroundImage: "#FFDF00"
    }
  };

  return (
    <div className="card" style={styles.profileComponentStyle}>
      <div className="card-content" style={styles.cardContentStyle}>
        <div className="field">
          {(theIssue.issuePhoto && (
            <div className="issue-card-image-div">
              <div className="issue-card-button-over-image">
                <ul className="issue-card-button-over-image-ul">
                  {stockTicker && (
                    <li className="issue-card-button-over-image-li">
                      <Link
                        to={`/company/${theIssue.companyKey}`}
                        style={styles.linkWhiteStyle}
                      >
                        {companyName}
                      </Link>
                      &nbsp; &nbsp;
                      {stockTicker}
                    </li>
                  )}
                  <li
                    className="issue-card-button-over-image-li"
                    id="stock-price"
                  >
                    {stockPrice}
                  </li>
                  {/*  <li
                              className="issue-card-button-over-image-li"
                              id="stock-change"
                            >
                         + 0.138
                            </li>*/}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={theIssue.citation}
                  >
                    <FontAwesome
                      name="fas fa-external-link"
                      className="issue-card-button-retweet-icon"
                    />
                  </a>
                  <li />
                </ul>
              </div>
              <img
                src={theIssue.issuePhoto}
                className="issue-card-image"
                alt={`a reflection of ${theIssue.title}`}
              />
              {/* <div className={this.state.sharing}>
                    <ul className="shareIcons">
                      <li>
                        <FacebookShareButton
                          url={`https://sustain.netlify.com/issue/${
                            this.props.issue.issueUid
                          }`}
                          quote={this.props.issue.title}
                        >
                          <FontAwesome name="fab fa-facebook-f facebook-blue fa-2x" />
                        </FacebookShareButton>
                      </li>
                      <li>
                        <TwitterShareButton
                          size={32}
                          round={true}
                          title={this.props.issue.title}
                          url={`https://sustain.netlify.com/issue/${
                            this.props.issue.issueUid
                          }`}
                        >
                          <FontAwesome name="fab fa-twitter twitter-blue fa-2x" />
                        </TwitterShareButton>
                      </li>
                    </ul>
                  </div> */}
              <div className="twitter-share-div">
                {/* <a onClick={this.showShare}> */}
                {/* <TwitterShareButton
                  size={32}
                  round={true}
                  title={this.props.issue.title}
                  url={`https://sustain.netlify.com/issue/${
                    this.props.issue.issueUid
                  }`}
                  className="twitter-share-button"
                >
                  <FontAwesome name="fal fa-share fa-lg" />
                </TwitterShareButton> */}
              </div>
              {/* </a> */}
            </div>
          )) ||
            theIssue.title}
          {/* </Link> */}
          <br />
          {/* {this.props.currentIssueId === this.props.issue.issueUid && (
            <Confetti active={this.props.confetti} config={config} />
          )} */}
          <Link to={`/issue/${theIssue.issueUid}`} style={styles.linkStyle}>
            <h3>
              <b>{theIssue.title}</b>
            </h3>
          </Link>
        </div>

        <div className="field">
          <p className="issue-card-paragraph">{theIssue.overImageSentence}</p>
        </div>

        {/*  <button
          onClick={this.toggleSummaryClassVisible}
          className={this.state.readMore}
        >
     <p id="issueCard-button-read">Read More </p> 
        </button>*/}
        <div className="field">
          {theVote === true ? (
            <h2 className="user-vote">
              {" "}
              {props.user.name} voted <b>Sustainable</b>{" "}
            </h2>
          ) : (
            <h2 className="user-vote">
              {props.user.name} voted <b>Un Sustainable</b>
            </h2>
          )}
        </div>
        <div>
          <VoteResults
            plus={theIssue.plusCount}
            minus={theIssue.minusCount}
            userVote={theVote}
          />
        </div>
        <div className="field">
          <Comments
            props={props}
            item={theIssue}
            currentTheUserVote={theVote}
          />
        </div>
      </div>
    </div>
  );
};

export default UserIssueCard;

/// End Here

// if (this.state.plusCount > 5) {
//   window.alert("NICE WORK");
// }
// const { issue } = this.props;
// let theUserVote = {};
// let cardColorClass = "";

// if (theUserVote.vote) {
//   theUserVote.true
//     ? (cardColorClass = "greenClass")
//     : (cardColorClass = "redClass");
// }

// const companyKey = this.props.issue.companyKey;

/* let theCompany = this.props.allCompanies.filter(
      company => company["companyUid"] === this.props.issue.companyKey
    );
    if (this.props.currentUserProps.vote) {
      theUserVote = this.props.currentUserProps.vote[`${this.props.issue.key}`];
      if (theUserVote === undefined) {
        theUserVote = {};
      }
    }

    let stockTicker;
    let companyName;

    if (theCompany[0] !== undefined) {
      stockTicker = theCompany[0]["stock"];
      companyName = theCompany[0]["name"];
    }

    let stockPrice;
    if (
      theCompany[0] !== undefined &&
      this.props.allStocks[`${stockTicker}`] !== undefined
    ) {
      stockPrice = this.props.allStocks[`${stockTicker}`].price;
    }

    if (this.state.company === "" && theCompany[0] !== undefined) {
      this.setState({
        company: companyName
      });
    }*/

// const mapStateToProps = state => ({
//   allIssues: state.issues.currentIssuesProps,
//   allCompanies: state.companies.currentCompaniesProps,
//   allStocks: state.stocks.currentStockProps,
//   currentUserProps: state.user.currentUserProps
// });

// export default connect(mapStateToProps)(IssueCard);
