import React, { Component } from "react";
import { connect } from "react-redux";
import { addComment, addReduxComment } from "../../actions/userActions";
var mixpanel = require("mixpanel-browser");

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      comment: ""
    };
  }
  toggleComments = () => {
    this.setState({
      visible: !this.state.visible
    });
  };
  //   renderComments = () => {
  renderComments = props => {
    if (props.comments === undefined) {
      return;
    }
    if (Object.keys(props.comments).length > 0) {
      return Object.values(props.comments).map(comment => {
        return (
          <li key={comment.key}>
            {comment.commentUserVoteStatus === true ? (
              <span className="display-name-vote-sustain">
                {comment.DisplayName}
              </span>
            ) : (
              <span className="display-name-vote-unsustain">
                {comment.DisplayName}
              </span>
            )}
            :&nbsp;
            {comment.text}
          </li>
        );
      });
    }
    // let Comments = ["Yes", "Ha", "Sketchy"];

    // return Comments.map(comment => {
    //   return <li>{comment}</li>;
    // });
  };
  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // resetComment = () => {

  // };
  handleSubmit = (e, props) => {
    e.preventDefault();
    let currentUserVote = "";
    if (props.currentTheUserVote) {
      if (
        props.currentTheUserVote.vote === true ||
        props.currentTheUserVote.vote === false
      ) {
        currentUserVote = props.currentTheUserVote.vote;
      }
    }

    if (
      (props.currentTheUserStateVote &&
        props.currentTheUserStateVote === false) ||
      (props.currentTheUserStateVote && props.currentTheUserStateVote === true)
    ) {
      currentUserVote = props.currentTheUserStateVote;
    }
    const IssueId = props.item.issueUid;
    const AuthorId = this.props.authUser.uid;
    const DisplayName = this.props.currentUserProps.username;
    addComment(
      this.state.comment,
      IssueId,
      AuthorId,
      DisplayName,
      currentUserVote
    );
    const companyName = this.props.allCompanies[`${props.item.companyKey}`][
      "name"
    ];
    addReduxComment(
      this.props.allIssues,
      this.state.comment,
      IssueId,
      DisplayName,
      currentUserVote
    );
    mixpanel.track("COMMENT", {
      company: companyName,
      issue_title: props.item.title,
      issue_uid: IssueId,
      commenter_email: this.props.authUser.email,
      commenter_name: DisplayName,
      commenter_uid: AuthorId,
      owner_email: this.props.currentUserProps.email,
      owner_uid: this.props.currentUserProps.uid,
      owner_name: this.props.currentUserProps.username
    });
    this.setState({
      comment: ""
    });
    // this.resetComment();
  };
  render() {
    let totalComments = 0;
    let count = 0;
    if (!this.props.item.comments) {
      totalComments = "Currently 0 comments";
    } else if (this.props.item.comments) {
      count = Object.keys(this.props.item.comments).length;
      if (count === 1) {
        totalComments = "Currently 1 comment";
      } else {
        totalComments = `Currently ${count} comments`;
      }
    }
    return (
      <div className="field">
        {this.state.visible ? (
          <div className="field">
            <h1>
              <button
                className="button comment-discuss"
                onClick={() => this.toggleComments()}
              >
                Hide Discussion
              </button>
            </h1>
            <ul>{this.renderComments(this.props.item)}</ul>
            <br />
            <div className="field">
              <textarea
                name="comment"
                className="textarea"
                rows="3"
                type="text"
                onChange={this.handleInputChange}
                value={this.state.comment}
              />
            </div>

            <div className="field comment-submit-buttons">
              <button
                className="button is-primary is-small"
                onClick={e => this.handleSubmit(e, this.props)}
              >
                Submit Comment
              </button>{" "}
            </div>
          </div>
        ) : (
          <h1>
            <button
              className="button comment-discuss"
              onClick={() => this.toggleComments()}
            >
              Discuss?
            </button>
            <br />
            {totalComments}
          </h1>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  allIssues: state.issues.currentIssuesProps,
  allCompanies: state.companies.allCompaniesObject,
  authUser: state.session.authUser,
  currentUserProps: state.user.currentUserProps
});

export default connect(mapStateToProps)(Comments);
