import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getAllSymbolsCompanies, getAllSymbolsIssues } from "../actions";

class SymbolChecker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockSymbol: ""
    };
  }
  componentDidMount() {
    !this.props.companiesLoaded && getAllSymbolsCompanies();
    !this.props.issuesLoaded && getAllSymbolsIssues();
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value.toUpperCase() });
  };
  renderCompany = () => {
    if (this.props.companies[this.state.stockSymbol]) {
      const theCompany = this.props.companies[this.state.stockSymbol];
      return (
        <div>
          <h2>{theCompany.name}</h2>

          <p>{this.renderStats(theCompany.companyUid)}</p>
        </div>
      );
    } else {
      return <h2>Enter a symbol</h2>;
    }
  };

  renderStats = uid => {
    if (uid) {
      const issues = this.filterIssues(uid);
      const averages = this.computeVotes(issues);
      return `${averages}% Sustainable`;
    }
  };
  computeVotes = issues => {
    let totalAvg = [];
    for (const item of issues) {
      const newAvg = this.average(item.plusCount, item.minusCount);
      if (newAvg > 0) {
        totalAvg.push(newAvg);
      }
    }
    let final = 0;
    for (let single of totalAvg) {
      final += single;
    }
    const result = final / totalAvg.length;
    return result.toFixed(2) * 100;
  };
  average = (plus = 0, minus = 0) => {
    const total = plus - minus;
    if (total > 0) {
      if (plus > 0) {
        return plus / total;
      } else {
        return 0;
      }
    } else return 0;
  };

  filterIssues = uid =>
    this.props.issues.filter(item => item.companyKey === uid);

  render() {
    return (
      <Fragment>
        <div className="issue-card-button-div">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <input
              type="text"
              placeholder="Ticker Symbols"
              value={this.state.stockSymbol}
              name="stockSymbol"
              onChange={e => this.handleChange(e)}
            />
            <br />
            <div>{this.renderCompany()}</div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  companies: state.symbols.symbolsCompanies,
  companiesLoaded: state.symbols.companiesLoaded,
  issues: state.symbols.symbolsIssues,
  issuesLoaded: state.symbols.issuesLoaded
});

export default connect(mapStateToProps)(SymbolChecker);
