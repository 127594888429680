const initialState = {
  symbolsCompanies: {},
  companiesLoaded: false,
  symbolsIssues: [],
  issuesLoaded: false
};

export default function symbolsReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_ALL_SYMBOLS_COMPANIES": {
      return {
        ...state,
        symbolsCompanies: action.allCompanies,
        companiesLoaded: true
      };
    }
    case "SET_ALL_SYMBOLS_ISSUES": {
      return {
        ...state,
        symbolsIssues: action.allIssues,
        issuesLoaded: true
      };
    }

    default:
      return state;
  }
}
